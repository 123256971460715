export const templates = [
  'food',
  'servicio-template-tiny2023',
  'tienda-fisica-template-tiny2023',
  'tienda-en-linea-template-tiny2023',
  'salon-de-belleza-template-tiny2023',
  'barberia-template-tiny2023',
  'restaurante-template-tiny2023',
  'carrito-de-comida-template-tiny2023',
  'plomero-template-tiny2023',
  'electricista-template-tiny2023',
  'mecanico-template-tiny2023',
  'envios-template-tiny2023',
  'consultorio-medico-template-tiny2023',
  'farmacia-template-tiny2023',
  'artista-template-tiny2023',
  'fotografo-template-tiny2023',
  'dentista-template-tiny2023',
  'servicios-legales-template-tiny2023',
  'venta-al-por-mayor-template-tiny2023',
  'diseno-grafico-template-tiny2023',
  'mantenimiento-template-tiny2023',
  'agencia-de-carros-template-tiny2023',
  'constructora-template-tiny2023',
  'consultor-de-negocios-template-tiny2023',
  'bienes-raices-template-tiny2023',
  'lavanderia-template-tiny2023',
  'electrodomesticos-template-tiny2023',
  'reclutamiento-template-tiny2023',
  'financiera-template-tiny2023',
  'envasadora-de-gas-template-tiny2023',
  'ferreteria-template-tiny2023',
  'floristeria-template-tiny2023',
  'fumigacion-template-tiny2023',
  'funeraria-template-tiny2023',
  'gimnasio-template-tiny2023',
  'copiado-template-tiny2023',
  'jardineria-template-tiny2023',
  'licoreria-template-tiny2023',
  'muebleria-template-tiny2023',
  'optica-template-tiny2023',
  'eventos-template-tiny2023',
  'alquiler-de-vehiculos-template-tiny2023',
  'veterinaria-template-tiny2023',
  'clinica-template-tiny2023',
  'armeria-template-tiny2023',
  'colegio-template-tiny2023',
  'instituto-de-idiomas-template-tiny2023',
  'academia-en-linea-template-tiny2023',
  'discoteca-template-tiny2023',
  'club-nocturno-template-tiny2023',
  'salon-de-juegos-template-tiny2023',
  'drink-template-tiny2023',
  'iglesia-template-tiny2023',
  'club-deportivo-template-tiny2023',
  'hotel-template-tiny2023',
  'motel-template-tiny2023',
  'jugueteria-template-tiny2023',
  'fabrica-de-muebles-template-tiny2023',
  'tapizeria-template-tiny2023',
  'escuela-de-musica-template-tiny2023',
  'seguros-template-tiny2023',
  'taller-template-tiny2023',
];

export const templateBusinessCategories = {
    'servicio-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.services',
        image: '/assets/img/app/template-icons/services.png',
        tag: 'service',
    },
    'tienda-fisica-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.store',
        image: '/assets/img/app/template-icons/store.png',
        tag: 'store',
    },
    'tienda-en-linea-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.online_store',
        image: '/assets/img/app/template-icons/online-store.png',
        tag: 'inlinestore',
    },
    'salon-de-belleza-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.salon_barbershop',
        image: '/assets/img/app/template-icons/salon-barbershop.png',
        tag: 'stylist',
    },
    'restaurante-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.restaurant',
        image: '/assets/img/app/template-icons/restaurant.png',
        tag: 'restaurant',
    },
    'carrito-de-comida-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.foodtruck',
        image: '/assets/img/app/template-icons/foodtruck.png',
        tag: 'foodtruck',
    },
    'plomero-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.plumber',
        image: '/assets/img/app/template-icons/plumber.png',
        tag: 'plumber',
    },
    'electricista-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.electrician',
        image: '/assets/img/app/template-icons/electrician.png',
        tag: 'electrician',
    },
    'mecanico-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.mechanic',
        image: '/assets/img/app/template-icons/mechanic.png',
        tag: 'mechanic',
    },
    'envios-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.delivery',
        image: '/assets/img/app/template-icons/delivery.png',
        tag: 'delivery',
    },
    'consultorio-medico-template-tiny2023':{
        label: 'hubWorkflow.businesstypes_step.doctor',
        image: '/assets/img/app/template-icons/doctor.png',
        tag: 'doctor',
    },
    'farmacia-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.pharmacy',
        image: '/assets/img/app/template-icons/pharmacy.png',
        tag: 'pharmacy',
    },
    'artista-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.artist',
        image: '/assets/img/app/template-icons/artist.png',
        tag: 'artist',
    },
    'fotografo-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.photographer',
        image: '/assets/img/app/template-icons/photographer.png',
        tag: 'photographer',
    },
    'dentista-template-tiny2023': {
        label: 'hubWorkflow.businesstypes_step.dentist',
        image: '/assets/img/app/template-icons/dentist.png',
        tag: 'dentist',
    }
};
