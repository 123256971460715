import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import CURRENCY_SYMBOLS from '../../util/currency-symbols';
import { FREE_PAYMENT_TYPES, PaymentType } from '../../models/payment-type-enum';
import { faLink, faWallet } from '@fortawesome/free-solid-svg-icons';
import {
  faCreditCard,
  faMoneyBillAlt,
} from '@fortawesome/free-regular-svg-icons';
import { AppSettings } from '../../app.settings';
import { ImageService } from '../../service/image.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../auth/auth.serviceV2';
import { Router } from '@angular/router';
import { RouteConstants } from '../../app.constants';
import { HubEntity } from '../../models/hub';
import { CashReturn } from '../../models/cash-return-enum';
import { AccountStatus } from '../../models/account-status-enum';
import { CreditCardValidators } from 'angular-cc-library';
import { isImage } from '../../util/image-utils';

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss'],
})
export class SelectPaymentMethodComponent {
  @Input() buyLabel: string = 'productList.place_order';
  @Input() form: UntypedFormGroup;
  @Input() hub: HubEntity;
  @Input() totalPrice: UntypedFormControl;
  @Input() disabled: boolean = false;
  @Input() isMenu: boolean = false;
  @Output() sendRequest: EventEmitter<any> = new EventEmitter();

  readonly currencySymbols = CURRENCY_SYMBOLS;
  readonly faWallet = faWallet;
  readonly faCreditCard = faCreditCard;
  readonly faMoneyBillAlt = faMoneyBillAlt;
  readonly faLink = faLink;

  settings;

  constructor(
    public appSettings: AppSettings,
    protected imageService: ImageService,
    protected snackBar: MatSnackBar,
    private authService: AuthService,
    public router: Router,
    private fb: UntypedFormBuilder,
  ) {
    this.settings = this.appSettings.settings;
  }

  get CashReturn() {
    return CashReturn;
  }

  get PaymentType() {
    return PaymentType;
  }

  get serviceType() {
    return this.form.get('serviceType') as UntypedFormControl;
  }

  get selectedPaymentType() {
    return this.form.get('chosenPaymentType') as UntypedFormControl;
  }

  get paymentImage() {
    return this.form.get('paymentImage') as UntypedFormControl;
  }

  get ccInAdvanceInfoForm() {
    return this.form.get('ccInAdvanceInfo') as UntypedFormGroup;
  }

  get cardHolder() {
    if (!this.ccInAdvanceInfoForm) return null;
    return this.ccInAdvanceInfoForm.get('cardHolder') as UntypedFormControl;
  }

  get cardNumber() {
    if (!this.ccInAdvanceInfoForm) return null;
    return this.ccInAdvanceInfoForm.get('cardNumber') as UntypedFormControl;
  }

  get expirationDate() {
    if (!this.ccInAdvanceInfoForm) return null;
    return this.ccInAdvanceInfoForm.get('expirationDate') as UntypedFormControl;
  }

  get cvv() {
    if (!this.ccInAdvanceInfoForm) return null;
    return this.ccInAdvanceInfoForm.get('cvv') as UntypedFormControl;
  }

  async saveImage(image: FileList) {
    if(!isImage(image.item(0).name)) {
      return;
    }
    this.settings.loadingSpinner = true;
    if (this.paymentImage.value) {
      try {
        await this.imageService
          .deleteImage(this.paymentImage.value)
          .toPromise();
      } catch (error) {
        this.settings.loadingSpinner = false;
        this.showSnackBar('Error Processing Your Request', 'OK', 2000);
        console.error('Image error: ', error);
      }
    }

    try {
      const url = await this.imageService.uploadImage(image.item(0));
      this.paymentImage.setValue(url);
      this.paymentImage.markAsDirty();
      this.settings.loadingSpinner = false;
    } catch (error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Image error: ', error);
    }
  }

  async removeImage() {
    this.settings.loadingSpinner = true;
    try {
      await this.imageService.deleteImage(this.paymentImage.value).toPromise();
      this.paymentImage.setValue('');
      this.paymentImage.markAsDirty();
      this.settings.loadingSpinner = false;
    } catch (error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Image error: ', error);
    }
  }

  async uploadImage($event, imagesInput) {
    if (!(await this.authService.isAuthenticated())) {
      localStorage.setItem('return_url', this.router.url);
      localStorage.setItem('return_from_login_url', this.router.url);
      await this.router.navigate([RouteConstants.auth]);
      return;
    }
    imagesInput.click();
    $event.preventDefault();
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass,
    });
  }

  pay() {
    this.sendRequest.emit();
  }

  getTotalPrice() {
    return Number(this.totalPrice.value);
  }

  isPaymentTypeSupported(paymentType: PaymentType): boolean {
    if (
      this.hub?.subType !== AccountStatus.PREMIUM && this.hub?.subType !== AccountStatus.ROYAL && !FREE_PAYMENT_TYPES.includes(paymentType)
    ) {
      return false;
    }
    
    return (
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.includes(paymentType)
    );

  }

  changePaymentType({ value }) {
    if (value === PaymentType.CC_IN_ADVANCE) {
      this.form.addControl(
        'ccInAdvanceInfo',
        this.fb.group({
          cardHolder: ['', Validators.required],
          cardNumber: ['', [Validators.required, CreditCardValidators.validateCCNumber]],
          expirationDate: ['', [Validators.required, CreditCardValidators.validateExpDate]],
          cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
        })
      );
    } else {
      this.form.removeControl('ccInAdvanceInfo');
    }
    this.form.updateValueAndValidity();
  }
}
