import { Component, OnInit } from '@angular/core';
import { RouteConstants } from '../../../app.constants';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity-settings',
  templateUrl: './activity-settings.component.html',
  styleUrls: ['./activity-settings.component.scss'],
})
export class ActivitySettingsComponent implements OnInit {
  activity = null;
  hubId = null;
  categoryId = null;
  handler = null;
  activityTypes;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.handler = this.route.snapshot.queryParams['handler'];
  }

  goToInstallTemplate(): void {
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/template?hubId=${this.hubId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }


  goToCreateActivity(): void {
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/manage?hubId=${this.hubId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  goToCloneHub(): void {
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/clone?hubId=${this.hubId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }


  back() {
    if (this.categoryId) {
      const start = this.handler
        ? `${this.handler}/category`
        : `/${RouteConstants.category}`;
      let url = `${start}/owner?hubId=${this.hubId}&categoryId=${this.categoryId}`;
      if (this.handler) {
        url += `&handler=${this.handler}`;
      }
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
    }
  }
}
