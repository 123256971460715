export class RouteConstants {
    public static get landingPage(): string { return '/'; }
    public static get home(): string { return '/~home'; }
    public static get hub(): string { return '/~hub'; }
    public static get searchLocation(): string { return '/~search-location'; }
    public static get searchVenue(): string { return '/~search-venue'; }
    public static get category(): string { return '/~category'; }
    public static get activity(): string { return '/~activity'; }
    public static get activitiesSettings(): string { return '/~activities-settings'; }
    public static get actinfo(): string { return '/~actinfo'; }
    public static get actrqt(): string { return '/~actrqt'; }
    public static get actrvt(): string { return '/~actrvt'; }
    public static get actpl(): string { return '/~actpl'; }
    public static get actmenu(): string { return '/~actmenu'; }
    public static get landing(): string { return '/~landing'; }
    public static get auth(): string { return '/~auth'; }
    public static get register(): string { return '/~register'; }
    public static get callback(): string { return '/callback'; }
    public static get error(): string { return '/~error'; }
    public static get notFound(): string { return '/~not-found'; }
    public static get settings(): string { return '/~settings'; }
    public static get pay(): string { return '/~pay'; }
    public static get requests(): string { return '/~requests'; }
    public static get promocodes(): string { return '/~promocodes'; }
    public static get userpromocodes(): string { return '/~userpromocodes'; }
    public static get reports(): string { return '/~reports'; }
    public static get returnUrl(): string { return 'return_url'; }
}
