<div class="grid" [ngClass]="{ 'grid-cols-1': user, 'sm:grid-cols-2': !user }">
  <div
    *ngIf="!user"
    class="text-left p-4 border-trueGray-300 border bg-white mb-4 sm:mb-0 sm:mx-4 rounded order-1"
  >
    <h2
      class="font-extrabold leading-none text-2xl text-primary mb-2 text-left mt-4"
      translate
    >
      landing.pricing_free_title
    </h2>
    <p class="text-primary text-base mb-5 text-left" translate>
      landing.pricing_free_subtitle
    </p>
    <h1 class="text-4xl font-semibold leading-none text-primary mb-5 text-left">
      $0
    </h1>
    <a
      [routerLink]="['/~hub/owner']"
      target="_blank"
      class="shadow-lg p-2 text-lg font-semibold text-center text-accent rounded bg-transparent border-accent border w-full block mb-8"
      translate
    >
      landing.pricing_free_button
    </a>

    <ul>
      <li class="flex flex-nowrap text-primary my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_1</span>
      </li>
      <li class="flex flex-nowrap text-primary my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_2</span>
      </li>
      <li class="flex flex-nowrap text-primary my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_3</span>
      </li>
      <li class="flex flex-nowrap text-primary my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_4</span>
      </li>
      <li class="flex flex-nowrap text-primary my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_5</span>
      </li>
      <li class="flex flex-nowrap text-primary my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_6</span>
      </li>
      <li class="flex flex-nowrap text-primary my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_7</span>
      </li>
      <li class="flex flex-nowrap text-trueGray-600 my-4 items-center">
        <mat-icon
          class="text-primary rounded-full bg-coolGray-50 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >close</mat-icon
        >
        <span translate>landing.pricing_feature_8</span>
      </li>
      <li class="flex flex-nowrap text-trueGray-600 my-4 items-center">
        <mat-icon
          class="text-primary rounded-full bg-coolGray-50 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >close</mat-icon
        >
        <span translate>landing.pricing_feature_9</span>
      </li>
      <li class="flex flex-nowrap text-trueGray-600 my-4 items-center">
        <mat-icon
          class="text-primary rounded-full bg-coolGray-50 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >close</mat-icon
        >
        <span translate>landing.pricing_feature_10</span>
      </li>
      <li class="flex flex-nowrap text-trueGray-600 my-4 items-center">
        <mat-icon
          class="text-primary rounded-full bg-coolGray-50 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >close</mat-icon
        >
        <span translate>landing.pricing_feature_11</span>
      </li>
      <li class="flex flex-nowrap text-trueGray-600 my-4 items-center">
        <mat-icon
          class="text-primary rounded-full bg-coolGray-50 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >close</mat-icon
        >
        <span translate>landing.pricing_feature_12</span>
      </li>
    </ul>
  </div>

  <div
    class="text-left mb-4 sm:mb-0 sm:mx-4 rounded"
    [ngClass]="{
      '!mx-0 !mb-8': user,
      'order-3 sm:col-span-2 sm:mt-4': !isPricingPage,
      'order-2': isPricingPage
    }"
  >
    <div
      class="text-left p-4 bg-primary rounded"
      [ngClass]="{
        '!mx-0': user,
        'sm:max-w-[450px] !mx-auto': !isPricingPage
      }"
    >
      <h2
        class="font-extrabold leading-none text-2xl text-gray-100 mb-2 text-left mt-4"
        translate
      >
        landing.pricing_royal_title
      </h2>
      <p class="text-gray-100 text-base mb-5 text-left" translate>
        landing.pricing_royal_subtitle
      </p>
      <p class="text-gray-100 text-base mb-5 text-left" translate>
        landing.pricing_royal_subtitle_2
      </p>
      <h1
        class="text-4xl font-semibold leading-none text-gray-100 mb-5 text-left flex items-center"
      >
        ${{ RoyalSubscriptionPrice[royalBillFrequency] }}
        <span class="text-base">&nbsp;/&nbsp;</span>
        <span class="text-base">{{
          'labels.frequency_' + royalBillFrequency | translate
        }}</span>
      </h1>

      <mat-button-toggle-group
        class="bill-frequency mb-4 mx-auto sm:mx-0 border-none"
        [(value)]="royalBillFrequency"
        (change)="billFrequencyChange($event)"
      >
        <mat-button-toggle color="accent" [value]="SubscriptionOption.ONE_MONTH"
          ><span translate>labels.frequency_ONE_MONTH</span></mat-button-toggle
        >
        <mat-button-toggle
          color="accent"
          [value]="SubscriptionOption.SIX_MONTHS"
        >
          <span class="btn-name" translate>labels.frequency_SIX_MONTHS</span>
          <span
            class="saving"
            translate
            [translateParams]="{
              discount: calculateRoyalSaving(SubscriptionOption.SIX_MONTHS)
            }"
            >labels.discount_percentage</span
          >
        </mat-button-toggle>
        <mat-button-toggle
          color="accent"
          [value]="SubscriptionOption.TWELVE_MONTHS"
        >
          <span class="btn-name" translate>labels.frequency_TWELVE_MONTHS</span>
          <span
            class="saving"
            translate
            [translateParams]="{
              discount: calculateRoyalSaving(SubscriptionOption.TWELVE_MONTHS)
            }"
            >labels.discount_percentage</span
          >
        </mat-button-toggle>
      </mat-button-toggle-group>

      <a
        *ngIf="!user"
        [routerLink]="['/~hub/owner']"
        target="_blank"
        class="shadow-lg p-2 text-lg font-semibold text-center text-white rounded bg-yelloworange border-yelloworange border w-full block mb-8"
        translate
      >
        landing.pricing_royal_button
      </a>
      <a
        *ngIf="user"
        target="_blank"
        (click)="stripePayRoyal()"
        class="shadow-lg p-2 text-lg font-semibold text-center text-white rounded bg-yelloworange border-yelloworange border w-full block mb-8 cursor-pointer uppercase"
        translate
      >
        landing.pricing_royal_button
      </a>
      <ul>
        <li class="flex flex-nowrap text-gray-100 my-4 items-center">
          <mat-icon
            class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
            >check</mat-icon
          >
          <span translate>landing.pricing_royal_feature_1</span>
        </li>
        <li class="flex flex-nowrap text-gray-100 my-4 items-center">
          <mat-icon
            class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
            >check</mat-icon
          >
          <span translate>landing.pricing_royal_feature_2</span>
        </li>
        <li class="flex flex-nowrap text-gray-100 my-4 items-center">
          <mat-icon
            class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
            >check</mat-icon
          >
          <span translate>landing.pricing_royal_feature_3</span>
        </li>
        <li class="flex flex-nowrap text-gray-100 my-4 items-center">
          <mat-icon
            class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
            >check</mat-icon
          >
          <span translate>landing.pricing_royal_feature_4</span>
        </li>
        <li class="flex flex-nowrap text-gray-100 my-4 items-center">
          <mat-icon
            class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
            >check</mat-icon
          >
          <span translate>landing.pricing_royal_feature_5</span>
        </li>
        <li class="flex flex-nowrap text-gray-100 my-4 items-center">
          <mat-icon
            class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
            >check</mat-icon
          >
          <span translate>landing.pricing_feature_6</span>
        </li>
      </ul>
      <p class="text-gray-100 text-base mb-5 pt-5 text-left" translate>
        landing.pricing_royal_footer
      </p>
      <p class="text-gray-100 text-base mb-5 text-left" translate>
        landing.pricing_royal_footer_2
      </p>
    </div>
  </div>

  <div
    class="text-left p-4 bg-primary mb-4 sm:mb-0 sm:mx-4 rounded"
    [ngClass]="{
      '!mx-0': user,
      'order-2': !isPricingPage,
      'order-3': isPricingPage
    }"
  >
    <h2
      class="font-extrabold leading-none text-2xl text-gray-100 mb-2 text-left mt-4"
      translate
    >
      landing.pricing_premium_title
    </h2>
    <p class="text-gray-100 text-base mb-5 text-left" translate>
      landing.pricing_premium_subtitle
    </p>
    <h1
      class="text-4xl font-semibold leading-none text-gray-100 mb-5 text-left flex items-center"
    >
      ${{ SubscriptionPrice[billFrequency] }}
      <span class="text-base">&nbsp;/&nbsp;</span>
      <span class="text-base">{{
        'labels.frequency_' + billFrequency | translate
      }}</span>
    </h1>

    <mat-button-toggle-group
      class="bill-frequency mb-4 mx-auto sm:mx-0 border-none"
      [(value)]="billFrequency"
      (change)="billFrequencyChange($event)"
    >
      <mat-button-toggle color="accent" [value]="SubscriptionOption.ONE_MONTH"
        ><span translate>labels.frequency_ONE_MONTH</span></mat-button-toggle
      >
      <mat-button-toggle color="accent" [value]="SubscriptionOption.SIX_MONTHS">
        <span class="btn-name" translate>labels.frequency_SIX_MONTHS</span>
        <span
          class="saving"
          translate
          [translateParams]="{
            discount: calculateSaving(SubscriptionOption.SIX_MONTHS)
          }"
          >labels.discount_percentage</span
        >
      </mat-button-toggle>
      <mat-button-toggle
        color="accent"
        [value]="SubscriptionOption.TWELVE_MONTHS"
      >
        <span class="btn-name" translate>labels.frequency_TWELVE_MONTHS</span>
        <span
          class="saving"
          translate
          [translateParams]="{
            discount: calculateSaving(SubscriptionOption.TWELVE_MONTHS)
          }"
          >labels.discount_percentage</span
        >
      </mat-button-toggle>
    </mat-button-toggle-group>
    <a
      *ngIf="!user"
      [routerLink]="['/~hub/owner']"
      target="_blank"
      class="shadow-lg p-2 text-lg font-semibold text-center text-white rounded bg-accent border-accent border w-full block mb-8"
      translate
    >
      landing.pricing_premium_button
    </a>
    <a
      *ngIf="user"
      target="_blank"
      (click)="stripePay()"
      class="shadow-lg p-2 text-lg font-semibold text-center text-white rounded bg-accent border-accent border w-full block mb-8 cursor-pointer uppercase"
      translate
    >
      buttons.buy_plan
    </a>
    <ul>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_1</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_2</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_3</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_4</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_5</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_6</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_7</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_8</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_9</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_10</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_11</span>
      </li>
      <li class="flex flex-nowrap text-gray-100 my-4 items-center">
        <mat-icon
          class="text-accent rounded-full bg-emerald-100 mr-2 text-base flex items-center justify-center text-center min-w-[24px]"
          >check</mat-icon
        >
        <span translate>landing.pricing_feature_12</span>
      </li>
    </ul>
  </div>
</div>
