<div fxLayout="row">
  <button (click)="back()" mat-button>
    <span translate>tinyAll.back</span>
  </button>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center start">
  <app-info-banner
    class="m-2"
    title="activityManagement.createActivity"
    description="messages.activity_management_description"
  ></app-info-banner>

  <div class="flex-p" fxFlex="100" fxFlex.gt-sm="50">
    <mat-card class="activities-card">
      <mat-card-header>
        <mat-card-subtitle>
          <h2 translate>activityManagement.title</h2>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-radio-group
          [(ngModel)]="activity"
          fxLayout="column"
          fxLayoutGap="10px"
          fxLayoutAlign="center"
        >
          <ng-container *ngFor="let actType of activityTypes">
            <div
              class="activity-options"
              *ngIf="actType.type !== 'category' || !categoryId"
            >
              <mat-radio-button [value]="actType" mb-6>
                {{ actType.displayName }}
              </mat-radio-button>
              <mat-divider></mat-divider>
            </div>
          </ng-container>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="flex-p" fxFlex="100" fxFlex.gt-sm="50">
    <mat-card *ngIf="activity">
      <mat-card-header class="activity-description">
        <mat-card-subtitle class="!mb-8">
          <h2>{{ activity.displayName }}</h2>
          <button
            class="create-activity-top-button"
            [disabled]="this.activity == null"
            type="button"
            mat-raised-button
            (click)="goToActivity()"
            color="accent"
          >
            <span translate>activityManagement.createActivity</span>
          </button>
        </mat-card-subtitle>
        <mat-card-subtitle>{{ activity.subtitle }}</mat-card-subtitle>
      </mat-card-header>
      <img
        class="activity-description-img"
        mat-card-image
        [src]="activity.image"
        alt="Activity Image"
      />
      <mat-card-content>
        <p [innerHTML]="activity.text"></p>
      </mat-card-content>
      <section fxLayoutAlign="center end">
        <button
          [disabled]="this.activity == null"
          type="button"
          mat-raised-button
          (click)="goToActivity()"
          color="accent"
        >
          <span translate>activityManagement.createActivity</span>
        </button>
      </section>
    </mat-card>
  </div>

  <app-activity-cloner
    [hubId]="hubId"
    [categoryId]="categoryId"
    [handler]="handler"
  ></app-activity-cloner>
</div>
