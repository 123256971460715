import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Country } from '@angular-material-extensions/select-country';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from '../../../app.settings';
import { HttpService } from '../../../service/http.service';
import { ImageService } from '../../../service/image.service';
import { BaseFormComponent } from '../../baseform.component';
import { RouteConstants } from '../../../app.constants';
import EntityTypeToUrlMap from '../../../util/entity-type-to-url-map';
import {
  getCountries,
  getCountryFromAlpha3Code,
} from '../../../util/countries';

import { HubEntity } from '../../../models/hub';
import { PageEntity } from '../../../models/types';
import { UserAdditionalInfoService } from '../../authentication/login/user-additional-info.service';
import { environment } from '../../../../environments/environment';
import { templates, templateBusinessCategories } from '../../../util/templates';
import { ShortUrlHandlerComponent } from '../../../components/short-url-handler/short-url-handler.component';
import { HubClonerComponent } from '../../../components/hub-cloner/hub-cloner.component';
import Swal from 'sweetalert2';
import { EntityType } from '../../../models/entity-type-enum';
import { AuthService } from '../../../auth/auth.serviceV2';
import { HubCreationStatus } from '../../../models/hub-creation-status-enum';
import { YesNoDialogComponent } from '../../../components/yes-no-dialog/yes-no-dialog.component';
import { ContentType } from '../../../models/content-type-enum';

import { FeatureFlagsService } from '../../../service/feature-flags.service';
import { delay } from '../../../util';
import { WorkflowInfoDialogComponent } from '../../hub/components/workflow-info-dialog/workflow-info-dialog.component';

@Component({
  selector: 'app-hub-template-workflow',
  templateUrl: './hub-template-workflow.component.html',
  styleUrls: ['./hub-template-workflow.component.scss'],
})
export class HubTemplateWorkflowComponent
  extends BaseFormComponent
  implements OnInit
{
  lastUpdate = new Date();
  daysOff = new Set<string>();
  canShowMap = false;

  predefinedCountries: Country[] = [];
  selectedCountry = null;

  readonly maxImageSize = environment.maxImageSizeInMB;
  readonly ContentType = ContentType;
  readonly ShortUrlHandlerComponent = ShortUrlHandlerComponent;

  hub: HubEntity;
  reseting = false;

  maxSizeErrors = {
    imageMaxSizeError: false,
    splashMaxSizeError: false,
    iconMaxSizeError: false,
  };

  selectedTab: number = 0;
  MAX_TAB_INDEX = 3;
  private mainContent;

  detailsForm: UntypedFormGroup;
  imagesForm: UntypedFormGroup;
  descriptionForm: UntypedFormGroup;
  hubAddressForm: UntypedFormGroup;
  locationForm: UntypedFormGroup;
  socialNetworksForm: UntypedFormGroup;
  scheduleForm: UntypedFormGroup;
  paymentMethodsForm: UntypedFormGroup;
  businessTypesForm: UntypedFormGroup;

  templateData;
  seledtedTemplateCategory;
  triedToSubmitBusinessType: boolean = false;
  triedToSubmitTemplate: boolean = false;
  cloningActivity?: string = null;
  clonedActivity: number = 0;
  totalActivities: number = 0;

  isSaving = false;

  activitiesData;
  currentActivity = 0;
  applySpecialPromoCode: boolean = false;
  currentLanguage;

  constructor(
    private authService: AuthService,
    protected router: Router,
    private route: ActivatedRoute,
    protected httpService: HttpService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    public _adapter: DatetimeAdapter<Date>,
    protected appSettings: AppSettings,
    protected snackBar: MatSnackBar,
    protected imageService: ImageService,
    public translate: TranslateService,
    public featureFlags: FeatureFlagsService,
    public dialog: MatDialog
  ) {
    super(
      httpService,
      appSettings,
      snackBar,
      imageService,
      router,
      dialog,
      translate
    );
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
    this.handler = this.route.snapshot.queryParams['handler'];
  }

  get qrCode() {
    if (!this.hub?.urlHandler) {
      return false;
    }
    return `${environment.appUrl}/${this.hub.urlHandler}`;
  }

  async ngOnInit() {
    this.predefinedCountries = getCountries(this.translate.getDefaultLang());
    this.mainContent = document.getElementById('main-content');
    this.settings.loadingSpinner = true;
    this.user = this.authService.getUserProfile();
    this.userAdditionalInfoService.check(this.router.url);
    this.currentLanguage =
      this.user && this.user.language
        ? this.user.language
        : this.translate.getBrowserLang();

    let currentStep = 0;
    await this.getHub();

    await this.getAllActivities();
    if (this.activitiesData) {
      currentStep = 2;
    }

    this.selectedTab = currentStep;
    this.openInfo(this.selectedTab);
    this.selectedIndexChanged(this.selectedTab);
  }

  get HubCreationStatus() {
    return HubCreationStatus;
  }

  async getHub() {
    this.settings.loadingSpinner = true;
    try {
      this.hub = await firstValueFrom(
        this.httpService.doGet(`${this.apiUrl}/hubs/v1`)
      );
      this.settings.loadingSpinner = false;
      this.hubId = this.hub.id;

      if (this.hub && this.hub.address && this.hub.address.country) {
        this.selectedCountry = getCountryFromAlpha3Code(
          this.hub.address.country,
          this.translate.getDefaultLang()
        );
      }
      return 2;
    } catch (error) {
      return 0;
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  back() {
    this.router.navigateByUrl(`${RouteConstants.hub}/owner`).then();
  }

  async goNext() {
    if (this.isSaving || this.selectedTab >= this.MAX_TAB_INDEX) return;
    setTimeout(() => {
      this.mainContent.scrollTop = 0;
    }, 200);
    this.selectedTab = this.selectedTab + 1;
    if (this.selectedTab === this.MAX_TAB_INDEX) return;
    this.openInfo(this.selectedTab);
  }

  goBack() {
    if (this.isSaving) return;
    if (this.selectedTab <= 0) {
      this.goToActivitiesSettings();
    }
    setTimeout(() => {
      this.mainContent.scrollTop = 0;
    }, 200);
    this.selectedTab = this.selectedTab - 1;
  }

  goTo(index: number) {
    if (this.isSaving) return;
    setTimeout(() => {
      this.mainContent.scrollTop = 0;
    }, 200);
    this.selectedTab = index;
    this.openInfo(this.selectedTab);
  }

  openInfo(index: number) {
    const infoContent = this.getInfoContent(index);
    const infoAudio = this.getInfoAudio(index);
    if (!infoContent) return;
    const moreInfoLink = this.getActivityLink();
    this.dialog.open(WorkflowInfoDialogComponent, {
      width: '100vw',
      height: 'fit-content',
      maxWidth: '600px',
      maxHeight: '100vh',
      panelClass: 'workflow-info-dialog',
      data: {
        content: infoContent,
        audio: infoAudio,
        moreInfoLink: moreInfoLink,
      },
    });
  }

  getInfoContent(index: number) {
    switch (index) {
      case 0:
        return this.translate.instant('hubWorkflow.businesstypes_step.info');
      case 1:
        return this.translate.instant('hubWorkflow.templates_step.info');
      case 2:
        if (!this.activitiesData || !this.activitiesData[this.currentActivity])
          return null;
        return this.translate.instant(
          `hubWorkflow.activities_step.${
            this.activitiesData[this.currentActivity].contentType
          }`
        );
      default:
        return '';
    }
  }

  getInfoAudio(index: number) {
    const lang = this.translate.getDefaultLang();
    switch (index) {
      case 0:
        return `/assets/audio/workflow/${lang}/step-9.mp3`;
      case 1:
        return `/assets/audio/workflow/${lang}/step-10.mp3`;
      case 2:
        return null;
      default:
        return '';
    }
  }

  getActivityLink() {
    if (!this.activitiesData || !this.activitiesData[this.currentActivity])
      return null;
    switch (this.activitiesData[this.currentActivity].contentType) {
      case ContentType.ACTIVITY_INFO:
        return 'https://tinyall.app/su/a/5c39784e-df33-488e-b86d-73b0c4ecdb05';
      case ContentType.ACTIVITY_REQUEST:
        return 'https://tinyall.app/su/a/edf18214-ef76-4d50-af03-6cd1140053a6';
      case ContentType.ACTIVITY_RESERVATION:
        return 'https://tinyall.app/su/a/7643d995-05c7-4958-b19a-adb0d59779e7';
      case ContentType.ACTIVITY_MENU:
        return null;
      case ContentType.ACTIVITY_PRODUCT_LISTING:
        return 'https://tinyall.app/su/a/ec7e0380-e298-4984-9c81-a0db21b1f915';
      case ContentType.ACTIVITY_EVENT:
        return 'https://tinyall.app/su/a/089a1c50-1388-45c5-a4fe-880ea0b95eb6';
      case ContentType.CATEGORY:
        return 'https://tinyall.app/su/a/38f4b952-cd19-4a82-b08c-9e42b99e4fa3';
      default:
        return null;
    }
  }

  selectedIndexChanged(index: number) {
    if (index === 0) {
      this.retriveHubTemplates();
      this.triedToSubmitBusinessType = false;
      this.seledtedTemplateCategory = null;
    }
    if (index <= 1) {
      this.triedToSubmitTemplate = false;
    }
  }

  async retriveHubTemplates() {
    this.settings.loadingSpinner = true;
    const querySearch: string[] = [];
    querySearch.push('querySearch=(');
    for (const tag of templates) {
      if (querySearch.length > 1) {
        querySearch.push(' OR ');
      }
      querySearch.push(`tags%23'${tag}'`);
    }
    querySearch.push(` AND tags%23'${this.currentLanguage === 'en' ? '2024-tiny-eng' : '2024-tiny-esp'}'`);
    querySearch.push(' AND active:true&page=0&size=500');
    const URL = `${this.apiUrl}/hubs/v1/search?${querySearch.join('')}`;
    try {
      const response: PageEntity<HubEntity> = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      if (response && response.content && response.content.length > 0) {
        this.templateData = [];
        templates.forEach((current) => {
          const hubs = response.content.filter((hub) =>
            hub.tags.includes(current)
          );
          const template = templateBusinessCategories[current];
          if (hubs && hubs.length > 0 && template) {
            this.templateData.push({
              ...template,
              hubs,
            });
          }
        });
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  goToTemplate(category) {
    this.seledtedTemplateCategory = category;
    this.goNext();
  }

  getHubUrl(handler: string) {
    return ShortUrlHandlerComponent.shortHubUrl(handler);
  }

  async cloneHub(hub: HubEntity) {
    if (this.cloningActivity) return;
    this.clonedActivity = 0;
    this.cloningActivity = hub.id;
    const activities = await HubClonerComponent.getActCatEntity(
      hub.id,
      this.httpService
    );
    this.totalActivities = activities.length;
    for (const catAct of activities) {
      const activity = catAct.content;

      const url = `${this.apiUrl}/${
        EntityTypeToUrlMap[activity.entityType]
      }/v1`;
      const body = {
        ...HubClonerComponent.getActivityBody(activity),
        hubId: this.hubId,
        userId: this.user.userId,
        active: true,
        includeInMenu: false,
        order: 0,
      };

      try {
        const response = await firstValueFrom(
          this.httpService.doPost(url, body)
        );
        await HubClonerComponent.cloneImages(response, this.imageService);
        if (activity.entityType === EntityType.CATEGORY) {
          const innerActivities = await HubClonerComponent.getInnerActCatEntity(
            activity.hubId,
            activity.id,
            this.httpService
          );
          for (const innerCatAct of innerActivities) {
            const innerActivity = innerCatAct.content;
            const innerUrl = `${this.apiUrl}/${
              EntityTypeToUrlMap[innerActivity.entityType]
            }/v1`;
            const innerBody = {
              ...HubClonerComponent.getActivityBody(innerActivity),
              hubId: this.hubId,
              userId: this.user.userId,
              categoryId: response.id,
              active: true,
              includeInMenu: false,
              order: 0,
            };
            try {
              const innerResponse = await firstValueFrom(
                this.httpService.doPost(innerUrl, innerBody)
              );
              await HubClonerComponent.cloneImages(
                innerResponse,
                this.imageService
              );
            } catch (error) {
              console.log(error);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.clonedActivity += 1;
      }
    }
    Swal.fire({
      titleText: this.translate.instant('titles.congratulations'),
      text: this.translate.instant('messages.cloning_complete'),
      icon: 'success',
      toast: true,
      position: 'top',
      iconColor: 'white',
      color: 'white',
      background: '#29A6A4',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    await this.getAllActivities();
    this.cloningActivity = null;
    this.goTo(2);
  }

  async getAllActivities() {
    this.settings.loadingSpinner = true;
    const URL = `${environment.apiUrl}/actcatview/v1/owner-search?page=0&size=500&querySearch=(hubId:'${this.hub.id}')&sort=updatedOn,desc`;
    try {
      const response = await firstValueFrom(this.httpService.doGet(URL));
      if (response && response.content && response.content.length) {
        this.activitiesData = response.content;
        if (this.activitiesData.length > 0) {
          const localCurrentActivity = this.activitiesData.findIndex(
            (activity) => activity.createdOn == activity.updatedOn
          );
          if (localCurrentActivity == -1) {
            this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
          } else {
            this.currentActivity = localCurrentActivity;
          }
        }
      }
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  getPercentage(clonedActivity) {
    if (this.clonedActivity === 0 || this.totalActivities === 0) return 0;
    return `${(clonedActivity / this.totalActivities) * 100}%`;
  }

  tryToClone(hub: HubEntity): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.clone_hub_question',
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.cloneHub(hub);
      }
    });
  }

  isActivity(type: string): boolean {
    return type.startsWith('ACTIVITY');
  }

  isCategory(type: string): boolean {
    return type.startsWith('CATEGORY');
  }

  async nextActivity() {
    if (this.currentActivity + 1 >= this.activitiesData.length) {
      //this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
      Swal.fire({
        titleText: this.translate.instant('titles.congratulations'),
        text: this.translate.instant('messages.activities_complete'),
        icon: 'success',
        toast: true,
        position: 'top',
        iconColor: 'white',
        color: 'white',
        background: '#29A6A4',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      this.goTo(3);
    } else {
      if (
        this.activitiesData[this.currentActivity].contentType ===
        this.activitiesData[this.currentActivity + 1].contentType
      ) {
        this.reseting = true;
        this.settings.loadingSpinner = true;
        await delay(300);
      }
      this.currentActivity += 1;
      this.openInfo(this.selectedTab);
      this.settings.loadingSpinner = false;
    }
    this.reseting = false;
    this.mainContent.scrollTop = 0;
  }

  goToActivitiesSettings(): void {
    const start = this.handler
      ? `${this.handler}/activity`
      : `/${RouteConstants.activity}`;
    let url = `/${start}/settings?hubId=${this.hub.id}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  done(): void {
    this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
  }
}
