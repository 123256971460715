<div
  *ngIf="selectedTab < MAX_TAB_INDEX"
  class="flex flex-col fixed -ml-[8px] w-full top-[56px] z-10 bg-white"
>
  <div class="bg-accent h-2 w-full"></div>
  <div
    *ngIf="selectedTab > 0 && selectedTab <= MAX_TAB_INDEX"
    class="flex w-full px-2 py-3 justify-between items-center max-w-[600px] sm:mx-auto"
  >
    <button
      *ngIf="selectedTab < MAX_TAB_INDEX"
      [disabled]="cloningActivity"
      class="rounded px-3 py-1 w-fit font-semibold disabled:opacity-60"
      [ngClass]="selectedTab === MAX_TAB_INDEX ? 'text-white' : 'text-primary'"
      (click)="goBack()"
    >
      <span translate>buttons.back</span> |
    </button>
    <div class="flex justify-around items-center flex-grow">
      <span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 0 ? '!bg-accent' : ''"
      ></span>
      <span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 1 ? '!bg-accent' : ''"
      ></span>
      <span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 2 ? '!bg-accent' : ''"
      ></span>
      <span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 3 ? '!bg-accent' : ''"
      ></span>
      <span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 4 ? '!bg-accent' : ''"
      ></span>
      <span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 5 ? '!bg-accent' : ''"
      ></span>
      <span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 6 ? '!bg-accent' : ''"
      ></span>
      <span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 7 ? '!bg-accent' : ''"
      ></span>
      <!--<span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 8 ? '!bg-accent' : ''"
      ></span>-->
      <!--<span
        class="bg-gray-300 rounded-full w-2 h-2"
        [ngClass]="selectedTab > 9 ? '!bg-accent' : ''"
      ></span>-->
    </div>
    <button
      [disabled]="cloningActivity"
      class="rounded px-3 py-1 w-fit font-semibold disabled:opacity-60"
      [ngClass]="selectedTab === MAX_TAB_INDEX ? 'text-white' : 'text-primary'"
      (click)="goNext()"
    >
      | <span translate>buttons.forward</span>
    </button>
  </div>
</div>

<div
  *ngIf="selectedTab === MAX_TAB_INDEX"
  class="flex flex-col fixed -ml-[8px] w-full top-[56px] z-10"
>
  <div class="h-2 w-full"></div>
  <div
    class="flex w-full px-2 py-3 justify-end items-center"
  >
    <button
      class="rounded px-3 py-1 w-fit font-semibold disabled:opacity-60 text-accent"
      (click)="done()"
    >
      | <span translate>buttons.done</span>
    </button>
  </div>
</div>

<div class="page-container" fxLayout="column">
  <mat-tab-group
    [(selectedIndex)]="selectedTab"
    (selectedIndexChange)="selectedIndexChanged($event)"
    class="header-less-tabs"
  >
    <!--introl_step-->
    <mat-tab>
      <ng-template matTabContent>
        <div class="px-5 py-12 gap-3 flex flex-col">
          <audio *ngIf="introAudio" controls [src]="introAudio"></audio>
          <h1 class="text-primary font-semibold text-2xl" translate>
            hubWorkflow.intro_step.title
          </h1>
          <h2 class="text-primary font-semibold" translate>
            hubWorkflow.intro_step.subtitle
          </h2>
          <p class="text-primary text-justify" translate>
            hubWorkflow.intro_step.p1
          </p>
          <p class="text-primary text-justify" translate>
            hubWorkflow.intro_step.p2
          </p>
          <p class="text-primary text-justify" translate>
            hubWorkflow.intro_step.p3
          </p>
          <p class="text-primary text-justify" translate>
            hubWorkflow.intro_step.p4
          </p>
          <p class="text-primary text-justify" translate>
            hubWorkflow.intro_step.p5
          </p>
          <p class="text-primary text-justify" translate>
            hubWorkflow.intro_step.p6
          </p>
          <button
            class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
            (click)="goNext()"
          >
            <span translate>hubWorkflow.intro_step.button</span>
          </button>
        </div>
      </ng-template>
    </mat-tab>
    <!--details_step-->
    <mat-tab>
      <ng-template matTabContent>
        <form
          novalidate
          autocomplete="off"
          [formGroup]="detailsForm"
          class="px-5 pb-12 pt-16 gap-3 flex flex-col"
        >
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubManagement.hub_details
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'hubManagement.name' | translate }}"
              formControlName="name"
            />
            <mat-error *ngIf="detailsFormName.hasError('required')" translate
              >hubManagement.error_name</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <app-info-tooltip
              matSuffix
              info="tooltip.hub_info_section_account_name"
              class="info-account-name"
              infoClass="secondary"
            ></app-info-tooltip>
            <input
              matInput
              placeholder="{{ 'hubManagement.url_handler' | translate }}"
              formControlName="urlHandler"
              (keydown.space)="$event.preventDefault()"
            />
            <mat-icon matPrefix class="at-icon">alternate_email</mat-icon>
            <mat-hint *ngIf="!hubId" translate
              >hubManagement.url_handler_hint</mat-hint
            >
            <mat-error
              *ngIf="detailsFormUrlHandler.hasError('required')"
              translate
              >hubManagement.error_url_handler</mat-error
            >
            <mat-error
              *ngIf="detailsFormUrlHandler.hasError('pattern')"
              translate
              >hubManagement.error_pattern_url_handler</mat-error
            >
            <mat-error
              *ngIf="detailsFormUrlHandler.hasError('maxlength')"
              translate
              >hubManagement.error_max_url_handler</mat-error
            >
            <mat-error
              *ngIf="detailsFormUrlHandler.hasError('minlength')"
              translate
              >hubManagement.error_min_url_handler</mat-error
            >
            <mat-error
              *ngIf="detailsFormUrlHandler.hasError('existing')"
              translate
              >hubManagement.error_existing_url_handler</mat-error
            >
          </mat-form-field>
          <app-url-handler-example
            title="titles.link_to_share"
            [urlHandler]="detailsFormUrlHandler.value"
            [copy]="hasHubId()"
          ></app-url-handler-example>
          <div class="warning" *ngIf="!hubId">
            <mat-icon>warning</mat-icon>
            <span translate>messages.url_hanlder_warning</span>
          </div>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'hubManagement.email' | translate }}"
              formControlName="email"
              (keydown.space)="$event.preventDefault()"
            />
            <mat-error
              *ngIf="
                detailsFormEmail.hasError('email') ||
                detailsFormEmail.hasError('required')
              "
              translate
              >hubManagement.error_email</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'hubManagement.phone' | translate }}"
              mask="(000) 000-0000"
              formControlName="phoneNumber"
            />
            <mat-error
              *ngIf="
                detailsFormPhoneNumber.hasError('required') ||
                detailsFormPhoneNumber.hasError('pattern')
              "
              translate
              >hubManagement.error_phone</mat-error
            >
          </mat-form-field>

          <app-hub-tag-picker [items]="detailsFormGetTags()">
          </app-hub-tag-picker>

          <div class="flex justify-end">
            <button
              class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
              (click)="onSubmit(HubCreationStatus.INITIAL_DETAILS)"
            >
              <span *ngIf="!isSaving" translate>buttons.save</span>
              <mat-spinner diameter="25" *ngIf="isSaving"></mat-spinner>
            </button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
    <!--images_step-->
    <mat-tab>
      <ng-template matTabContent>
        <form
          novalidate
          autocomplete="off"
          [formGroup]="imagesForm"
          class="px-5 pb-12 pt-16 gap-3 flex flex-col"
        >
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubManagement.hub_details
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>
          <p translate>
            hubManagement.image<app-info-tooltip
              info="tooltip.hub_images_section_imagen"
              infoClass="secondary"
            ></app-info-tooltip>
          </p>
          <section fxLayoutAlign="center">
            <input
              hidden="true"
              type="file"
              accept="image/*"
              (change)="
                onSelectedImage($any($event).target.files, imagesForm, 'image')
              "
              #imageInput
            />
            <a (click)="imageInput.click()">
              <img
                height="120"
                [src]="imagesForm.get('image').value || noImage"
              />
            </a>
          </section>
          <mat-error class="error" *ngIf="maxSizeErrors['image']" translate>{{
            'formErrors.image_max_size' | translate : { size: maxImageSize }
          }}</mat-error>

          <mat-error
            *ngIf="imagesForm.touched && imagesFormImage.hasError('required')"
            translate
            >formErrors.field_required</mat-error
          >
          <p translate>
            hubManagement.icon<app-info-tooltip
              info="tooltip.hub_images_section_icon"
              infoClass="secondary"
            ></app-info-tooltip>
          </p>
          <section fxLayoutAlign="center">
            <input
              hidden="true"
              type="file"
              accept="image/*"
              (change)="
                onSelectedImage($any($event).target.files, imagesForm, 'icon')
              "
              #iconInput
            />
            <a (click)="iconInput.click()">
              <img
                height="120"
                [src]="imagesForm.get('icon').value || noImage"
              />
            </a>
          </section>
          <mat-error class="error" *ngIf="maxSizeErrors['icon']" translate>{{
            'formErrors.image_max_size' | translate : { size: maxImageSize }
          }}</mat-error>
          <mat-error
            *ngIf="imagesForm.touched && imagesFormIcon.hasError('required')"
            translate
            >formErrors.field_required</mat-error
          >
          <div class="flex justify-end">
            <button
              class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
              (click)="onSubmit(HubCreationStatus.IMAGES)"
            >
              <span *ngIf="!isSaving" translate>buttons.save</span>
              <mat-spinner diameter="25" *ngIf="isSaving"></mat-spinner>
            </button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
    <!--address_step-->
    <mat-tab>
      <ng-template matTabContent>
        <form
          novalidate
          autocomplete="off"
          [formGroup]="hubAddressForm"
          class="px-5 pb-12 pt-16 gap-3 flex flex-col"
        >
          <div class="flex justify-between">
            <div>
              <h2 class="text-primary font-semibold" translate>
                hubManagement.address
              </h2>
              <p class="text-gray-500" translate>
                hubWorkflow.address_step.subtitle
              </p>
            </div>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>
          <mat-select-country
            [countries]="predefinedCountries"
            placeHolder="{{ 'hubManagement.country' | translate }}"
            [value]="selectedCountry"
            [itemsLoadSize]="10"
            (onCountrySelected)="hubAddressFormOnCountrySelected($event)"
          >
          </mat-select-country>
          <mat-error
            *ngIf="
              hubAddressForm.touched &&
              hubAddressFormCountry.hasError('required')
            "
            class="-mt-4 text-xs"
            translate
            >hubManagement.error_country</mat-error
          >

          <app-state-autocomplete
            [state]="hubAddressFormStateOrProvidence"
          ></app-state-autocomplete>

          <app-city-autocomplete
            [city]="hubAddressFormCity"
          ></app-city-autocomplete>

          <mat-form-field>
            <textarea
              matInput
              placeholder="{{ 'hubManagement.address' | translate }}"
              formControlName="address"
            ></textarea>
            <mat-error
              *ngIf="hubAddressFormAddress.hasError('required')"
              translate
              >hubManagement.error_address</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <textarea
              matInput
              placeholder="{{ 'hubManagement.address2' | translate }}"
              formControlName="address2"
            ></textarea>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'hubManagement.zip_code' | translate }}"
              formControlName="zipCode"
            />
          </mat-form-field>

          <mat-form-field>
            <textarea
              matInput
              placeholder="{{ 'hubManagement.location_hint' | translate }}"
              formControlName="locationHint"
            ></textarea>
          </mat-form-field>
          <div class="flex justify-end">
            <button
              class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
              (click)="onSubmit(HubCreationStatus.ADDRESS)"
            >
              <span *ngIf="!isSaving" translate>buttons.save</span>
              <mat-spinner diameter="25" *ngIf="isSaving"></mat-spinner>
            </button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
    <!--description_step-->
    <mat-tab>
      <ng-template matTabContent>
        <form
          novalidate
          autocomplete="off"
          [formGroup]="descriptionForm"
          class="px-5 pb-12 pt-16 gap-3 flex flex-col"
        >
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubWorkflow.description_step.title
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>
          <app-rich-text-editor
            [form]="descriptionForm"
            fieldName="infoText"
            mode="edit"
            placeholder="{{ 'formFields.description' | translate }}"
          ></app-rich-text-editor>

          <!--<section fxLayout="column" fxLayoutAlign="space-between stretch">
            <app-warning-message
              *ngIf="!descriptionFormActive.value"
              message="messages.inactive_hub"
            >
            </app-warning-message>
            <mat-checkbox formControlName="active">
              <span translate
                >hubManagement.activate_checkbox<app-info-tooltip
                  class="checkbox-tooltip"
                  info="tooltip.publish_hub_checkbox"
                  infoClass="secondary"
                ></app-info-tooltip
              ></span>
            </mat-checkbox>
            <p class="field-description" translate>
              messages.hub_public_checkbox_description
            </p>
          </section>-->
          <!--<section
            fxLayout="column"
            fxLayoutAlign="space-between stretch"
            class="hub-hidden-check"
          >
            <mat-checkbox formControlName="unlisted">
              <span class="info-tooltip-container" translate
                >hubManagement.hidden_checkbox<app-info-tooltip
                  class="checkbox-tooltip"
                  info="tooltip.hidden_hub_checkbox"
                  infoClass="secondary"
                ></app-info-tooltip
              ></span>
            </mat-checkbox>
            <p class="field-description" translate>
              messages.hub_hidden_checkbox_description
            </p>
          </section>-->
          <section fxLayout="column" fxLayoutAlign="space-between stretch" class="hub-hidden-check">
              <mat-checkbox formControlName="adultContent">
                  <span class="info-tooltip-container" translate>hubManagement.mature_content</span>
              </mat-checkbox>
          </section>
          <div class="flex justify-end">
            <button
              class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
              (click)="onSubmit(HubCreationStatus.DESCRIPTION)"
            >
              <span *ngIf="!isSaving" translate>buttons.save</span>
              <mat-spinner diameter="25" *ngIf="isSaving"></mat-spinner>
            </button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
    <!--location_step-->
    <mat-tab>
      <ng-template matTabContent>
        <form
          novalidate
          autocomplete="off"
          [formGroup]="locationForm"
          class="px-5 pb-12 pt-16 gap-3 flex flex-col"
        >
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubManagement.location
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>
          <section fxLayoutAlign="start center">
            <button
              type="button"
              (click)="locationFormSetLocation()"
              mat-mini-fab
              color="primary"
            >
              <mat-icon>add_location</mat-icon>
            </button>
            <p class="select-location-message" translate>
              messages.select_current_location_hub
            </p>
          </section>
          <div fxLayout="column" fxLayoutAlign="center">
            <div class="flex-p map-wrapper">
              <app-location-map
                [noCard]="true"
                [location]="locationFormLocation"
                name="Your Location"
                [launch]="false"
                [world]="true"
              >
              </app-location-map>
            </div>
          </div>

          <div class="flex justify-end">
            <button
              class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
              (click)="onSubmit(HubCreationStatus.LOCATION)"
            >
              <span *ngIf="!isSaving" translate>buttons.save</span>
              <mat-spinner diameter="25" *ngIf="isSaving"></mat-spinner>
            </button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
    <!--socialnetworks_step-->
    <mat-tab>
      <ng-template matTabContent>
        <form
          novalidate
          autocomplete="off"
          [formGroup]="socialNetworksForm"
          class="px-5 pb-12 pt-16 gap-3 flex flex-col"
        >
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubManagement.social_media_links
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>

          <app-social-media-links-management
            [form]="socialNetworksFormSocialLinks"
            [noCard]="true"
          >
          </app-social-media-links-management>

          <app-custom-links-management
            [form]="socialNetworksFormCustomLinks"
            [noCard]="true"
          >
          </app-custom-links-management>

          <div class="flex justify-end">
            <button
              class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
              (click)="onSubmit(HubCreationStatus.SOCIAL)"
            >
              <span *ngIf="!isSaving" translate>buttons.save</span>
              <mat-spinner diameter="25" *ngIf="isSaving"></mat-spinner>
            </button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
    <!--schedule_step-->
    <mat-tab>
      <ng-template matTabContent>
        <form
          novalidate
          autocomplete="off"
          [formGroup]="scheduleForm"
          class="px-5 pb-12 pt-16 gap-3 flex flex-col"
        >
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              subtitles.weekly_schedule
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>

          <app-open-hours-management [form]="scheduleForm" [noCard]="true">
          </app-open-hours-management>

          <h2 class="font-semibold text-base text-gray-600 mb-4" translate>
            subtitles.pick_up_days_off
          </h2>

          <app-days-off-picker
            [showTitle]="true"
            [noCard]="true"
            [daysSelected]="daysOff"
            (changed)="scheduleFormOnDaysOffChange()"
          >
          </app-days-off-picker>

          <div class="flex justify-end">
            <button
              class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
              (click)="onSubmit(HubCreationStatus.AVAILABILITY)"
            >
              <span *ngIf="!isSaving" translate>buttons.save</span>
              <mat-spinner diameter="25" *ngIf="isSaving"></mat-spinner>
            </button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
    <!--paymentmethods_step-->
    <mat-tab>
      <ng-template matTabContent>
        <div class="px-5 pb-12 pt-16 gap-3 flex flex-col">
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              titles.payment_methods
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>
          <div class="mt-4 -mx-4">
            <app-payment-methods
              [simplified]="true"
              (onSave)="onPaymentMethodUpdate($event)"
            ></app-payment-methods>
          </div>

          <div class="flex justify-between">
            <button
              class="bg-transparent text-accent text-lg rounded-full px-3 py-1 mt-4 w-fit"
              (click)="
                submitAndGoTo(HubCreationStatus.TEMPLATE, 9, true)
              "
            >
              <span class="font-semibold" translate
                >buttons.skip_this_step</span
              >
            </button>
            <button
              class="bg-accent text-white text-lg rounded-full px-3 py-1 mt-4 w-fit min-w-[80px] flex justify-center"
              (click)="submitAndGoTo(HubCreationStatus.TEMPLATE, 9, true)"
            >
              <span *ngIf="!isSaving" translate>buttons.save</span>
              <mat-spinner diameter="25" *ngIf="isSaving"></mat-spinner>
            </button>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!--businesstypes_step-->
    <!--<mat-tab>
      <ng-template matTabContent>
        <div class="px-5 pb-12 pt-14 gap-y-3 gap-x-2 flex flex-col">
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubWorkflow.businesstypes_step.title
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>

          <mat-error
            class="error"
            *ngIf="triedToSubmitBusinessType && !seledtedTemplateCategory"
            translate
            >formErrors.business_types_required</mat-error
          >

          <div class="grid grid-cols-3 gap-y-3 gap-x-2 mt-4">
            <div
              *ngFor="let category of templateData"
              class="flex flex-col justify-center items-center cursor-pointer"
              (click)="goToTemplate(category)"
            >
              <img class="h-16 w-16" [src]="category.image" alt="folder" />
              <h3 class="text-primary font-semibold mt-0.5">
                {{ category.label | translate }}
              </h3>
            </div>
          </div>

          <div class="flex justify-end">
            <button
              class="bg-transparent text-accent text-lg rounded-full px-3 py-1 mt-4 w-fit"
              (click)="submitAndGoTo(HubCreationStatus.TEMPLATE, 12, true)"
            >
              <span class="font-semibold" translate
                >buttons.skip_this_step</span
              >
            </button>
          </div>
        </div>
      </ng-template>
    </mat-tab>-->
    <!--template_step-->
    <!--<mat-tab>
      <ng-template matTabContent>
        <div class="px-5 pb-12 pt-14 gap-y-3 gap-x-2 flex flex-col" *ngIf="seledtedTemplateCategory">
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubWorkflow.templates_step.title
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>

          <mat-error class="error" *ngIf="triedToSubmitTemplate" translate
            >formErrors.template_required</mat-error
          >

          <div class="flex justify-start items-center cursor-pointer">
            <img
              class="h-10 w-10 template-subtitle-img"
              [src]="seledtedTemplateCategory.image"
            />
            <h3 class="text-accent font-semibold ml-2">
              <span translate>hubWorkflow.templates_step.subtitle</span
              ><span>{{ seledtedTemplateCategory.label | translate }}</span>
            </h3>
          </div>

          <div class="grid grid-cols-1 gap-y-4 gap-x-2 mt-4">
            <div
              *ngFor="let hub of seledtedTemplateCategory.hubs"
              class="relative border border-accent rounded-md overflow-hidden"
            >
              <img class="w-full h-auto" [src]="hub.image" alt="hub" />
              <div
                class="flex justify-between absolute bottom-0 left-0 right-0"
              >
                <a
                  *ngIf="cloningActivity === null || cloningActivity !== hub.id"
                  class="bg-white shadow-lg text-accent rounded-full px-3 py-1 mt-4 mb-2 ml-2 w-fit flex items-center"
                  [href]="getHubUrl(hub.urlHandler)"
                  target="_blank"
                >
                  <mat-icon class="mr-1">visibility</mat-icon>
                  <span class="font-semibold" translate
                    >hubWorkflow.templates_step.view_hub</span
                  >
                </a>
                <button
                  *ngIf="cloningActivity === null || cloningActivity !== hub.id"
                  [disabled]="cloningActivity"
                  class="bg-white shadow-lg text-accent rounded-full px-3 py-1 mt-4 mb-2 mr-2 w-fit flex items-center disabled:opacity-60"
                  (click)="tryToClone(hub)"
                >
                  <span class="font-semibold" translate
                    >hubWorkflow.templates_step.install_hub</span
                  >
                  <mat-icon class="ml-1">download</mat-icon>
                </button>
                <div *ngIf="cloningActivity == hub.id" class="px-4 mx-auto">
                  <div class="flex items-center justify-between mb-2 mt-3">
                    <p
                      class="text-white text-base drop-shadow-lg font-bold"
                      translate
                      [translateParams]="{
                        total: totalActivities,
                        completed: clonedActivity
                      }"
                    >
                      messages.cloning_activities
                    </p>
                  </div>
                  <div
                    class="w-full h-2 bg-gray-200 rounded-full drop-shadow-lg"
                  >
                    <div
                      class="w-2/3 h-full text-center text-xs text-white bg-primary rounded-full"
                      [ngStyle]="{ width: getPercentage(clonedActivity) }"
                    ></div>
                  </div>
                  <app-warning-message
                    message="messages.cloning_activities_warning"
                  >
                  </app-warning-message>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-end">
            <button
              [disabled]="cloningActivity"
              class="bg-transparent text-accent text-lg rounded-full px-3 py-1 mt-4 w-fit disabled:opacity-60"
              (click)="submitAndGoTo(HubCreationStatus.TEMPLATE, 12, true)"
            >
              <span class="font-semibold" translate
                >buttons.skip_this_step</span
              >
            </button>
          </div>
        </div>
      </ng-template>
    </mat-tab>-->
    <!--activities_step-->
    <!--<mat-tab>
      <ng-template matTabContent>
        <div
          *ngIf="activitiesData && !reseting"
          class="pb-12 pt-4 gap-y-3 gap-x-2 flex flex-col"
        >
          <div class="flex justify-between">
            <h2
              class="text-primary font-semibold px-5"
              translate
              [translateParams]="{
                current: currentActivity + 1,
                all: activitiesData.length
              }"
            >
              hubWorkflow.activities_step.title
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>
          <div
            class="grid grid-cols-1 gap-y-4 gap-x-2 mt-4"
            [ngSwitch]="activitiesData[currentActivity].contentType"
          >
            <app-actinfo-management
              *ngSwitchCase="ContentType.ACTIVITY_INFO"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-actinfo-management>
            <app-actrqt-management
              *ngSwitchCase="ContentType.ACTIVITY_REQUEST"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-actrqt-management>
            <app-actrsvn-management
              *ngSwitchCase="ContentType.ACTIVITY_RESERVATION"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-actrsvn-management>
            <app-activity-menu-management
              *ngSwitchCase="ContentType.ACTIVITY_MENU"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-activity-menu-management>
            <app-activity-product-listing-management
              *ngSwitchCase="ContentType.ACTIVITY_PRODUCT_LISTING"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-activity-product-listing-management>
            <app-activity-event-management
              *ngSwitchCase="ContentType.ACTIVITY_EVENT"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-activity-event-management>
            <app-category-management
              *ngSwitchCase="ContentType.CATEGORY"
              [hubIdParam]="hub.id"
              [categoryIdParam]="activitiesData[currentActivity].id"
              (next)="nextActivity()"
            ></app-category-management>
          </div>
        </div>
      </ng-template>
    </mat-tab>-->
    <!--final_step-->
    <mat-tab>
      <ng-template matTabContent>
        <div
          class="px-5 pt-32 gap-3 flex flex-col items-center min-h-full fixed left-0 right-0 bottom-0 top-0 max-w-lg mx-auto"
        >
          <video
            class="h-56 w-56"
            src="/assets/img/app/corneta.mp4"
            alt="check"
            autoplay
            loop
          ></video>
          <h1 class="text-accent font-semibold text-2xl mb-5" translate>
            hubWorkflow.final_step_with_activities.title
          </h1>
          <h2 class="text-accent font-semibold text-center text-lg mb-5" translate>
            hubWorkflow.final_step_with_activities.subtitle
          </h2>

          <app-url-handler-example class="w-full max-w-lg mx-2" *ngIf="hub" [urlHandler]="hub?.urlHandler" [copy]="true"></app-url-handler-example>
          <app-qr-generator-card class="w-full max-w-lg" *ngIf="qrCode" [text]="qrCode" [imageUrl]="hub?.image || noImage"></app-qr-generator-card>

          <p class="text-accent text-justify text-base mt-4" translate>
            hubWorkflow.final_step_with_activities.p1
          </p>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<app-loading></app-loading>
