<div class="header" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!categoryIdParam">
    <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <app-client-view-button [url]="qrCode"></app-client-view-button>
        <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button class="save"mat-menu-item (click)="onSubmit()" [disabled]="!form || (!form.dirty && !questionsForm.dirty) || form.invalid || questionsForm.invalid || hubId == null || hubId == 'undifined'"><span translate>buttons.save</span></button>
            <button class="delete" mat-menu-item (click)="canDelete()" *ngIf="categoryId"><span translate>buttons.delete</span></button>
        </mat-menu>
    </div>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-activity-breadcrumb *ngIf="!categoryIdParam" type="CATEGORY" (onBack)="back()"></app-activity-breadcrumb>
    <form novalidate *ngIf="form" [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
        <div class="flex-p" fxFlex="100">
            <mat-card class="card-container">
                <mat-card-content>
                    <div fxLayout="column" fxLayoutAlign="center">
                        <div hidden>
                            <input matInput type="text" formControlName="id">
                            <input matInput type="text" formControlName="userId">
                            <input matInput type="text" formControlName="hubId">
                        </div>
    
                        <mat-form-field class="name">
                            <input matInput placeholder="{{'activityRequest.name' | translate}}" formControlName="name">
                            <mat-error *ngIf="name.hasError('required')" translate>activityRequest.error_name</mat-error>
                        </mat-form-field>

                        <div class="relative">
                            <app-swiper-management [images]="images" 
                                [form]="form"
                                [allowVideo]="true">
                            </app-swiper-management>
                            <apan *ngIf="categoryId && active.value" class="bg-accent rounded-full px-2 py-1 text-white z-10 absolute top-[7px] left-[-5px] shadow-lg" translate>labels.active</apan>
                            <apan *ngIf="categoryId && !active.value" class="bg-red-600 rounded-full px-2 py-1 text-white z-10 absolute top-[7px] left-[-5px] shadow-lg" translate>labels.inactive</apan>
                        </div>
    
                        <app-rich-text-editor [form]="form" fieldName="infoText" mode="edit" placeholder="{{'formFields.description' | translate}}"></app-rich-text-editor>
                        <mat-error *ngIf="info.hasError('required')" translate>activityRequest.error_info</mat-error>
                        <div style="height:15px"></div>
    
                        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="7px">
                            <section fxLayout="column" fxLayoutAlign="space-between stretch">
                                <app-warning-message *ngIf="!active.value"
                                    message="messages.inactive_category">
                                </app-warning-message>
                                <mat-checkbox formControlName="active" [class]="getHighlightCheckbox('active')">
                                    <span translate>activityRequest.active<app-info-tooltip class="checkbox-tooltip" info="tooltip.active_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="unListed" [class]="getHighlightCheckbox('unListed')">
                                    <span translate>formFields.hidden<app-info-tooltip class="checkbox-tooltip" info="tooltip.hidden_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch" fxLayout="column">
                                <mat-checkbox formControlName="includeInMenu" [class]="getHighlightCheckbox('includeInMenu')">
                                    <span translate>formFields.add_to_navigation_menu<app-info-tooltip class="checkbox-tooltip" info="tooltip.in_menu_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                                <div *ngIf="showNavigationMenuLink">
                                    <span class="full-menu-text" translate>messages.navigation_menu_is_full</span>
                                    <button (click)="gotoNavigationMenu()" mat-button color="accent"><span translate>buttons.update</span></button>
                                </div>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="clonable" [class]="getHighlightCheckbox('clonable')">
                                    <span translate>formFields.clonable<app-info-tooltip class="checkbox-tooltip" info="tooltip.clonable_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="restricted" [disabled]="restrictedValue && (questionsForm.invalid || additionalChargesForm.invalid)" [class]="getHighlightCheckbox('restricted')">
                                    <span translate>categoryManagement.restricted<app-info-tooltip class="checkbox-tooltip" info="tooltip.category_retricted_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="gpsRequired" *ngIf="restrictedValue" [class]="getHighlightCheckbox('gpsRequired')">
                                    <span translate>activityRequest.gps<app-info-tooltip class="checkbox-tooltip" info="tooltip.gps_required_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="phoneNumberRequired" *ngIf="restrictedValue" [class]="getHighlightCheckbox('phoneNumberRequired')">
                                    <span translate>activityRequest.phone<app-info-tooltip class="checkbox-tooltip" info="tooltip.phone_required_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="addressRequired" *ngIf="restrictedValue" [class]="getHighlightCheckbox('addressRequired')">
                                    <span translate>activityRequest.address<app-info-tooltip class="checkbox-tooltip" info="tooltip.address_required_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <app-confirmation-message-management [form]="form"></app-confirmation-message-management>
        <app-additional-charges-management *ngIf="restrictedValue" [form]="additionalChargesForm"></app-additional-charges-management>
        <mat-divider></mat-divider>
        <app-question-list-management 
            #questionsManagement
            *ngIf="questionsForm && selectedInnerQuestion && restrictedValue"
            [questionsForm]="questionsForm" 
            [selectedInnerQuestion]="selectedInnerQuestion">
        </app-question-list-management>
    </form>
    <button
        class="save-speed-dial-action-button-fix"
        (click)="onSubmit()"
        mat-fab
        [disabled]="!form || (!form.dirty && !questionsForm.dirty && !originId && !hubIdParam) || form.invalid || questionsForm.invalid || hubId == null || hubId == 'undifined'"
        color="primary"
    >
        <span translate>tinyAll.save</span>
        <mat-icon>save</mat-icon>
    </button>
</div>
<app-loading></app-loading>
