<div fxLayout="row">
  <button (click)="back()" mat-button>
    <span translate>tinyAll.back</span>
  </button>
</div>

<div class="max-w-3xl flex flex-col justify-start items-center mx-auto p-2">
  <app-hub-cloner
    [userId]="hub.userId"
    [hubId]="hubId"
    [handler]="handler"
    [showVideo]="true"
    [defaultActive]="true"
    [shouldGoHome]="true"
  ></app-hub-cloner>
</div>
