import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../../../../app.constants';
import { ActCatViewEntity } from '../../../../models/activity-category-view';

@Component({
  selector: 'app-hub-readiness-checklist',
  templateUrl: './hub-readiness-checklist.component.html',
  styleUrls: ['./hub-readiness-checklist.component.scss'],
})
export class HubReadinessChecklistComponent {
  @Input() handler = null;
  @Input() hub = null;
  @Input() items: ActCatViewEntity[] = null;

  constructor(private router: Router) {}

  get hasActivities() {
    return (
      this.items &&
      this.items.length > 0
    );
  }

  get hasPaymentMethod() {
    return (
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.length > 0
    );
  }

  get show()  {
    return (!this.hub || !this.hasPaymentMethod || !this.hasActivities);
  }

  goToManageHub(): void {
    if (this.hub) return;
    this.router.navigate([this.handler || RouteConstants.hub, 'manage']).then();
  }

  goToFAQ(): void {
    return;
  }

  goToPayments(): void {
    if (this.hasPaymentMethod) return;
    this.router.navigate([this.handler || RouteConstants.hub, 'pay', 'payment-methods']);
  }

  goToCreateActivity(): void {
    if (this.hasActivities) return;
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/manage?hubId=${this.hub.id}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }


  goToActivitiesSettings(): void {
    if (this.hasActivities) return;
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/settings?hubId=${this.hub.id}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  goNext(): void {
    if (!this.hub) {
      this.goToManageHub();
    } else if (!this.hasActivities) {
      this.goToActivitiesSettings();
    } else {
      this.goToPayments();
    }
  }

}
