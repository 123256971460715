
<mat-card
  [ngClass]="{ error: name.hasError('required') }"
>
  <div [formGroup]="form" fxLayout="column" fxLayoutAlign="center">

    <mat-form-field>
      <input
        matInput
        placeholder="{{ 'formFields.location_name' | translate }}"
        formControlName="name"
      />
    </mat-form-field>
    <mat-checkbox formControlName="defaultLocation">
      <span class="info-tooltip-container" translate>formFields.default<app-info-tooltip info="tooltip.default_address" infoClass="secondary"></app-info-tooltip></span>
  </mat-checkbox>
  </div>
</mat-card>

<mat-expansion-panel [ngClass]="{ error: location.hasError('required') }" [disabled]="alwaysOpen" [expanded]="alwaysOpen" [hideToggle]="alwaysOpen">
  <mat-expansion-panel-header>
    <mat-panel-title translate
      >subtitles.select_your_location<app-info-tooltip
        info="tooltip.event_location"
        class="hub-manage-info"
      ></app-info-tooltip
    ></mat-panel-title>
  </mat-expansion-panel-header>

  <div class="map-container" (click)="openMap()">
    <div fxLayout="column">
      <mat-error *ngIf="location.hasError('required')" translate
        >formErrors.location_required_to_continue</mat-error
      >
      <div style="height: 10px"></div>
      <app-location-map [location]="location.value" [noCard]="true">
      </app-location-map>
    </div>
  </div>
</mat-expansion-panel>

<mat-expansion-panel [ngClass]="{ error: addressValidateError() }" [disabled]="alwaysOpen" [expanded]="alwaysOpen" [hideToggle]="alwaysOpen">
  <mat-expansion-panel-header>
    <mat-panel-title translate
      >hubManagement.address<app-info-tooltip
        info="tooltip.hub_address_section"
        class="hub-manage-info"
      ></app-info-tooltip
    ></mat-panel-title>
  </mat-expansion-panel-header>
  <div [formGroup]="addressForm" fxLayout="column" fxLayoutAlign="center">
    <mat-select-country
      [countries]="predefinedCountries"
      placeHolder="{{ 'hubManagement.country' | translate }}"
      [value]="selectedCountry"
      [itemsLoadSize]="10"
      (onCountrySelected)="onCountrySelected($event)"
    >
      <mat-error *ngIf="country.hasError('required')" translate
        >hubManagement.error_country</mat-error
      >
    </mat-select-country>

    <app-state-autocomplete
      [state]="stateOrProvidence"
    ></app-state-autocomplete>

    <app-city-autocomplete [city]="city"></app-city-autocomplete>

    <mat-form-field>
      <textarea
        matInput
        placeholder="{{ 'hubManagement.address' | translate }}"
        formControlName="address"
      ></textarea>
      <mat-error *ngIf="address.hasError('required')" translate
        >hubManagement.error_address</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <textarea
        matInput
        placeholder="{{ 'hubManagement.address2' | translate }}"
        formControlName="address2"
      ></textarea>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        placeholder="{{ 'hubManagement.zip_code' | translate }}"
        formControlName="zipCode"
      />
    </mat-form-field>

    <mat-form-field>
      <textarea
        matInput
        placeholder="{{ 'hubManagement.location_hint' | translate }}"
        formControlName="locationHint"
      ></textarea>
    </mat-form-field>
  </div>
</mat-expansion-panel>