<div fxLayout="row wrap" fxLayoutAlign="center start" class="mx-2">
  <div fxFlex="100">
    <mat-card class="bank-card">
      <div
        class="card-title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <h2 translate>labels.clone_hub</h2>
      </div>
      <mat-card-content>
        <P translate> messages.hub_cloner_message</P>
        <form novalidate *ngIf="form" [formGroup]="form">
          <div class="flex mt-3 mb-2">
            <mat-form-field appearance="outline" class="w-full h-16">
              <input
                matInput
                formControlName="hubUrl"
                placeholder="{{ 'formFields.hub_url' | translate }}"
                class="text-base"
              />
              <button
                mat-button
                *ngIf="!hub && hubUrlValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearHubUrl()"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error
                *ngIf="
                  hubUrl.hasError('required') ||
                  hubUrl.hasError('invalid')
                "
                translate
                >formErrors.hub_url_invalid</mat-error
              >
            </mat-form-field>
            <button
              [disabled]="cloning || loading"
              class="h-11 ml-4 mt-1.5 bg-accent text-white p-3 rounded disabled:bg-gray-300 disabled:text-gray-700"
              (click)="searchHub()"
            >
              <mat-icon *ngIf="!loading && !hub">search</mat-icon>
              <mat-icon *ngIf="!loading && hub">close</mat-icon>
              <mat-spinner diameter="25" *ngIf="loading"></mat-spinner>
            </button>
          </div>
        </form>

        <ng-container *ngIf="hub">
          <div class="flex items-stretch p-2 border-gray-300 border rounded">
            <img class="w-20 h-20 rounded" [src]="hub.image" alt="hub" />
            <div class="w-full mx-2">
              <h2 class="font-semibold">{{ hub.name }}</h2>
              <h2 class="font-base text-gray-500" *ngIf="activities" translate [translateParams]="{activities: activities.length}">
                messages.activities_to_clone
              </h2>
            </div>
            <button
              [disabled]="!activities"
              class="h-11 ml-4 mt-1.5 bg-accent text-white p-3 rounded disabled:bg-gray-300 disabled:text-gray-700 self-center"
              (click)="cloneHub()"
            >
              <span *ngIf="!cloning" translate>buttons.clone</span>
              <mat-spinner diameter="25" *ngIf="cloning"></mat-spinner>
            </button>
          </div>
          <mat-error class="mt-2" *ngIf="!activities" translate
            >formErrors.no_activity_to_be_cloned</mat-error
          >
        </ng-container>

        <ng-container *ngIf="cloning">
          <div class="flex items-center justify-between mb-2 mt-3">
            <p
              class="text-gray-500 text-sm"
              translate
              [translateParams]="{total: activities.length, completed: clonedActivity}"
            >
                messages.cloning_activities
            </p>
          </div>
          <div class="w-full h-2 bg-gray-200 rounded-full">
              <div class="w-2/3 h-full text-center text-xs text-white bg-primary rounded-full" [ngStyle]="{'width': getPercentage()}">
              </div>
          </div>
          <app-warning-message message="messages.cloning_activities_warning">
          </app-warning-message>
        </ng-container>

        <div *ngIf="showVideo" class="responsive-youtube-player">
          <youtube-player videoId="VuYjLee4IZo"></youtube-player>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
