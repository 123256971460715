import { Component, OnInit } from '@angular/core';
import { RouteConstants } from '../../../app.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { firstValueFrom } from 'rxjs';
import { HttpService } from '../../../service/http.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-clone-hub',
  templateUrl: './clone-hub.component.html',
  styleUrls: ['./clone-hub.component.scss'],
})
export class CloneHubComponent implements OnInit {
  readonly apiUrl = environment.apiUrl;
  hubId = null;
  categoryId = null;
  handler = null;
  hub;
  settings;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected appSettings: AppSettings,
    protected httpService: HttpService,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.handler = this.route.snapshot.queryParams['handler'];
    this.getHub();
  }

  goToCreateActivity(): void {
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/manage?hubId=${this.hubId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  back() {
    if (this.categoryId) {
      const start = this.handler
        ? `${this.handler}/category`
        : `/${RouteConstants.category}`;
      let url = `${start}/owner?hubId=${this.hubId}&categoryId=${this.categoryId}`;
      if (this.handler) {
        url += `&handler=${this.handler}`;
      }
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
    }
  }

  async getHub() {
    this.settings.loadingSpinner = true;
    try {
      this.hub = await firstValueFrom(
        this.httpService.doGet(`${this.apiUrl}/hubs/v1`)
      );
      this.hubId = this.hub.id;
    } catch (error) {
      console.log('Hub not found: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }
}
