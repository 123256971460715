import { Component, OnInit } from '@angular/core';
import { RouteConstants } from '../../../app.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { setDefaultPageHead } from '../../../util/seo-utils';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activity-management',
  templateUrl: './activity-management.component.html',
  styleUrls: ['./activity-management.component.scss'],
})
export class ActivityManagementComponent implements OnInit {
  activity = null;
  hubId = null;
  categoryId = null;
  handler = null;
  activityTypes;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.activityTypes = [
      {
        displayName: this.translate.instant('activityManagement.informative'),
        subtitle: this.translate.instant(
          'activityManagement.informative_subtitle'
        ),
        type: 'actinfo',
        text: this.translate.instant('activityManagement.informative_text'),
        image: '/assets/img/app/informative-activity.png',
        videoLink: 'http://youtube.com',
      },
      {
        displayName: this.translate.instant('activityManagement.request'),
        subtitle: this.translate.instant('activityManagement.request_subtitle'),
        type: 'actrqt',
        text: this.translate.instant('activityManagement.request_text'),
        image: '/assets/img/app/request-activity.png',
        videoLink: 'http://youtube.com',
      },
      {
        displayName: this.translate.instant('activityManagement.reservation'),
        subtitle: this.translate.instant(
          'activityManagement.reservation_subtitle'
        ),
        type: 'actrvt',
        text: this.translate.instant('activityManagement.reservation_text'),
        image: '/assets/img/app/reservation-activity.png',
        videoLink: 'http://youtube.com',
      },
      {
        displayName: this.translate.instant(
          'activityManagement.product_listing'
        ),
        subtitle: this.translate.instant(
          'activityManagement.product_listing_subtitle'
        ),
        type: 'actpl',
        text: this.translate.instant('activityManagement.product_listing_text'),
        image: '/assets/img/app/product-listing-activity.png',
        videoLink: 'http://youtube.com',
      },
      {
        displayName: this.translate.instant('activityManagement.menu'),
        subtitle: this.translate.instant('activityManagement.menu_subtitle'),
        type: 'actmenu',
        text: this.translate.instant('activityManagement.menu_text'),
        image: '/assets/img/app/menu-activity.png',
        videoLink: 'http://youtube.com',
      },
      {
        displayName: this.translate.instant('activityManagement.event'),
        subtitle: this.translate.instant('activityManagement.event_subtitle'),
        type: 'actevent',
        text: this.translate.instant('activityManagement.event_text'),
        image: '/assets/img/app/event-activity.png',
        videoLink: 'http://youtube.com',
      },
      {
        displayName: this.translate.instant('activityManagement.folder'),
        subtitle: this.translate.instant('activityManagement.folder_subtitle'),
        type: 'category',
        text: this.translate.instant('activityManagement.folder_text'),
        image: '/assets/img/app/folder-activity.png',
        videoLink: 'http://youtube.com',
      },
    ];

    this.activity = this.activityTypes[0];
  }

  ngOnInit() {
    setDefaultPageHead(this.titleService, this.metaTagService, true);
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.handler = this.route.snapshot.queryParams['handler'];
  }

  goToActivity(): void {
    const start = this.handler
      ? `${this.handler}/${this.activity.type}`
      : `/~${this.activity.type}`;
    let url = `/${start}/manage?hubId=${this.hubId}`;
    if (this.categoryId) {
      url += `&categoryId=${this.categoryId}`;
    }
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  back() {
    if (this.categoryId) {
      const start = this.handler
        ? `${this.handler}/category`
        : `/${RouteConstants.category}`;
      let url = `${start}/owner?hubId=${this.hubId}&categoryId=${this.categoryId}`;
      if (this.handler) {
        url += `&handler=${this.handler}`;
      }
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
    }
  }
}
