import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { ActivityManagementComponent } from './activity-management/activity-management.component';
import { AuthGuard } from '../../auth/auth.guard';
import { ComponentsModule } from '../../components/componets.module';
import { ActivitySettingsComponent } from './activity-settings/activity-settings.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CloneHubComponent } from './clone-hub/clone-hub.component';
import { HubTemplateWorkflowComponent } from './hub-template-workflow/hub-template-workflow.component';
import { ActivityInfoModule } from '../activity-info/activity-info.module';
import { ActivityEventModule } from '../activity-event/activity-event.module';
import { ActivityMenuModule } from '../activity-menu/activity-menu.module';
import { ActivityProductListingModule } from '../activity-product-listing/activity-product-listing.module';
import { ActivityRequestModule } from '../activity-request/activity-request.module';
import { ActivityReservationModule } from '../activity-reservation/activity-reservation.module';
import { CategoryModule } from '../category/category.module';

export const routes = [
  {
    path: 'settings',
    component: ActivitySettingsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'clone', component: CloneHubComponent, canActivate: [AuthGuard] },
  {
    path: 'manage',
    component: ActivityManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'template',
    component: HubTemplateWorkflowComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  providers: [],
  declarations: [
    ActivityManagementComponent,
    ActivitySettingsComponent,
    CloneHubComponent,
    HubTemplateWorkflowComponent,
  ],
  imports: [
    //RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ServiceModule,
    ComponentsModule,
    YouTubePlayerModule,
    ActivityInfoModule,
    ActivityEventModule,
    ActivityMenuModule,
    ActivityProductListingModule,
    ActivityRequestModule,
    ActivityReservationModule,
    CategoryModule,
  ],
})
export class ActivityModule {}
