<div class="flex flex-col fixed -ml-[8px] w-full top-[54px] z-10 bg-white">
  <div class="bg-accent h-2 w-full"></div>
  <div
    *ngIf="selectedTab < 2"
    class="flex w-full px-2 py-3 justify-between items-center max-w-[600px] sm:mx-auto"
  >
    <button
      [disabled]="cloningActivity"
      class="rounded px-3 py-1 w-fit font-semibold disabled:opacity-60 text-primary"
      (click)="goBack()"
    >
      <span translate>buttons.back</span> |
    </button>
  </div>
  <div
    *ngIf="selectedTab === 3"
    class="flex w-full px-2 py-3 justify-end items-center"
  >
    <button
      class="rounded px-3 py-1 w-fit font-semibold disabled:opacity-60 text-accent"
      (click)="done()"
    >
      | <span translate>buttons.done</span>
    </button>
  </div>
</div>

<div class="page-container" fxLayout="column">
  <mat-tab-group
    [(selectedIndex)]="selectedTab"
    (selectedIndexChange)="selectedIndexChanged($event)"
    class="header-less-tabs"
  >
    <!--businesstypes_step-->
    <mat-tab>
      <ng-template matTabContent>
        <div class="px-5 pb-12 pt-14 gap-y-3 gap-x-2 flex flex-col">
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubWorkflow.businesstypes_step.title
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>

          <mat-error
            class="error"
            *ngIf="triedToSubmitBusinessType && !seledtedTemplateCategory"
            translate
            >formErrors.business_types_required</mat-error
          >

          <div class="grid grid-cols-3 gap-y-3 gap-x-2 mt-4">
            <div
              *ngFor="let category of templateData"
              class="flex flex-col justify-center items-center cursor-pointer"
              (click)="goToTemplate(category)"
            >
              <img class="h-16 w-16" [src]="category.image" alt="folder" />
              <h3 class="text-primary font-semibold mt-0.5">
                {{ category.label | translate }}
              </h3>
              <label *ngIf="user.tags.includes(category.tag)" class="text-accent font-semibold" translate>labels.recommended</label>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!--template_step-->
    <mat-tab>
      <ng-template matTabContent>
        <div
          class="px-5 pb-12 pt-14 gap-y-3 gap-x-2 flex flex-col"
          *ngIf="seledtedTemplateCategory"
        >
          <div class="flex justify-between">
            <h2 class="text-primary font-semibold" translate>
              hubWorkflow.templates_step.title
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>

          <mat-error class="error" *ngIf="triedToSubmitTemplate" translate
            >formErrors.template_required</mat-error
          >

          <div class="flex justify-start items-center cursor-pointer">
            <img
              class="h-10 w-10 template-subtitle-img"
              [src]="seledtedTemplateCategory.image"
            />
            <h3 class="text-accent font-semibold ml-2">
              <span translate>hubWorkflow.templates_step.subtitle</span
              ><span>{{ seledtedTemplateCategory.label | translate }}</span>
            </h3>
          </div>

          <div class="grid grid-cols-1 gap-y-4 gap-x-2 mt-4">
            <div
              *ngFor="let hub of seledtedTemplateCategory.hubs"
              class="relative border border-accent rounded-md overflow-hidden"
            >
              <img class="w-full h-auto" [src]="hub.image" alt="hub" />
              <div
                class="flex justify-between absolute bottom-0 left-0 right-0"
              >
                <a
                  *ngIf="cloningActivity === null || cloningActivity !== hub.id"
                  class="bg-white shadow-lg text-accent rounded-full px-3 py-1 mt-4 mb-2 ml-2 w-fit flex items-center"
                  [href]="getHubUrl(hub.urlHandler)"
                  target="_blank"
                >
                  <mat-icon class="mr-1">visibility</mat-icon>
                  <span class="font-semibold" translate
                    >hubWorkflow.templates_step.view_hub</span
                  >
                </a>
                <button
                  *ngIf="cloningActivity === null || cloningActivity !== hub.id"
                  [disabled]="cloningActivity"
                  class="bg-white shadow-lg text-accent rounded-full px-3 py-1 mt-4 mb-2 mr-2 w-fit flex items-center disabled:opacity-60"
                  (click)="tryToClone(hub)"
                >
                  <span class="font-semibold" translate
                    >hubWorkflow.templates_step.install_hub</span
                  >
                  <mat-icon class="ml-1">download</mat-icon>
                </button>
                <div *ngIf="cloningActivity == hub.id" class="px-4 mx-auto">
                  <div class="flex items-center justify-between mb-2 mt-3">
                    <p
                      class="text-white text-base drop-shadow-lg font-bold"
                      translate
                      [translateParams]="{
                        total: totalActivities,
                        completed: clonedActivity
                      }"
                    >
                      messages.cloning_activities
                    </p>
                  </div>
                  <div
                    class="w-full h-2 bg-gray-200 rounded-full drop-shadow-lg"
                  >
                    <div
                      class="w-2/3 h-full text-center text-xs text-white bg-primary rounded-full"
                      [ngStyle]="{ width: getPercentage(clonedActivity) }"
                    ></div>
                  </div>
                  <app-warning-message
                    message="messages.cloning_activities_warning"
                  >
                  </app-warning-message>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!--activities_step-->
    <mat-tab>
      <ng-template matTabContent>
        <div
          *ngIf="activitiesData && !reseting"
          class="pb-12 pt-4 gap-y-3 gap-x-2 flex flex-col"
        >
          <div class="flex justify-between">
            <h2
              class="text-primary font-semibold px-5"
              translate
              [translateParams]="{
                current: currentActivity + 1,
                all: activitiesData.length
              }"
            >
              hubWorkflow.activities_step.title
            </h2>
            <button
              class="text-accent w-fit font-semibold flex items-center"
              (click)="openInfo(selectedTab)"
            >
              INFO <mat-icon class="ml-1">info</mat-icon>
            </button>
          </div>
          <div
            class="grid grid-cols-1 gap-y-4 gap-x-2 mt-4"
            [ngSwitch]="activitiesData[currentActivity].contentType"
          >
            <app-actinfo-management
              *ngSwitchCase="ContentType.ACTIVITY_INFO"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-actinfo-management>
            <app-actrqt-management
              *ngSwitchCase="ContentType.ACTIVITY_REQUEST"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-actrqt-management>
            <app-actrsvn-management
              *ngSwitchCase="ContentType.ACTIVITY_RESERVATION"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-actrsvn-management>
            <app-activity-menu-management
              *ngSwitchCase="ContentType.ACTIVITY_MENU"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-activity-menu-management>
            <app-activity-product-listing-management
              *ngSwitchCase="ContentType.ACTIVITY_PRODUCT_LISTING"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-activity-product-listing-management>
            <app-activity-event-management
              *ngSwitchCase="ContentType.ACTIVITY_EVENT"
              [hubIdParam]="hub.id"
              [activityIdParam]="activitiesData[currentActivity].id"
              [categoryIdParam]="
                activitiesData[currentActivity].content.categoryId
              "
              (next)="nextActivity()"
            ></app-activity-event-management>
            <app-category-management
              *ngSwitchCase="ContentType.CATEGORY"
              [hubIdParam]="hub.id"
              [categoryIdParam]="activitiesData[currentActivity].id"
              (next)="nextActivity()"
            ></app-category-management>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!--final_step-->
    <mat-tab>
      <ng-template matTabContent>
        <div
          class="px-5 pt-32 gap-3 flex flex-col items-center min-h-full fixed left-0 right-0 bottom-0 top-0 max-w-lg mx-auto"
        >
          <video
            class="h-56 w-56"
            src="/assets/img/app/corneta.mp4"
            alt="check"
            autoplay
            loop
          ></video>
          <h1 class="text-accent font-semibold text-2xl mb-5" translate>
            hubWorkflow.final_step_with_activities.title
          </h1>
          <h2
            class="text-accent font-semibold text-center text-lg mb-5"
            translate
          >
            hubWorkflow.final_step_with_activities.subtitle
          </h2>

          <app-url-handler-example
            class="w-full max-w-lg mx-2"
            *ngIf="hub"
            [urlHandler]="hub?.urlHandler"
            [copy]="true"
          ></app-url-handler-example>
          <app-qr-generator-card
            class="w-full max-w-lg"
            *ngIf="qrCode"
            [text]="qrCode"
            [imageUrl]="hub?.image || noImage"
          ></app-qr-generator-card>

          <p class="text-accent text-justify text-base mt-4" translate>
            hubWorkflow.final_step_with_activities.p1
          </p>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<app-loading></app-loading>
