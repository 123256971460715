<div fxLayout="row">
  <button (click)="back()" mat-button>
    <span translate>tinyAll.back</span>
  </button>
</div>

<div class="max-w-3xl flex flex-col justify-start items-center mx-auto p-2">
  <mat-card>
    <div>
      <h1
        class="text-primary text-[20px] font-semibold mb-4 leading-tight"
        translate
      >
        titles.activity_settings
      </h1>
      <p class="text-gray-500 text-[16px] mb-4" translate>
        messages.activity_settings
      </p>
      <div class="responsive-youtube-player">
        <youtube-player videoId="c_JYIywDcw8"> </youtube-player>
      </div>
    </div>
  </mat-card>

  <h1
    class="text-primary text-[20px] font-semibold mb-6 mt-5 leading-tight"
    translate
  >
    subtitles.activity_settings
  </h1>

  <button
    class="w-full py-1 px-4 h-12 font-bold text-lg text-white rounded bg-accent text-center shadow-md gap-2 grid grid-cols-[1fr_2fr_1fr] md:grid-cols-3 items-center mb-5"
    (click)="goToInstallTemplate()"
  >
    <div></div>
    <span class="whitespace-nowrap" translate>buttons.install_template</span>
    <span class="text-sm font-normal text-end" translate>labels.recommended</span>
  </button>
  <button
    class="w-full py-1 px-4 h-12 font-bold text-lg text-white rounded bg-accent text-center shadow-md flex items-center justify-center mb-5"
    (click)="goToCloneHub()"
  >
    <span class="whitespace-nowrap" translate>buttons.clone_hub</span>
  </button>
  <button
    class="w-full py-1 px-4 h-12 font-bold text-lg text-white rounded bg-accent text-center shadow-md flex items-center justify-center mb-5"
    (click)="goToCreateActivity()"
  >
    <span class="whitespace-nowrap" translate>buttons.create_activity</span>
  </button>
</div>
