<div *ngIf="!simplified" fxLayout="row" fxLayoutAlign="space-between center">
  <button (click)="back()" mat-button>
    <span translate>tinyAll.back</span>
  </button>
  <h2 class="title" translate>titles.payment_methods</h2>
</div>

<div fxLayout="column wrap" [ngClass]="simplified ? '' : 'page-container'">
  <app-info-banner
    *ngIf="!simplified"
    title="tooltip.payment_page_title"
    description="tooltip.payment_page_description"
  ></app-info-banner>

  <mat-expansion-panel
    class="border-accent border-[3px] !mx-2.5 !mb-4 !rounded-[25px] !overflow-visible"
    [expanded]="showPaymentMethod === 1"
    (opened)="setOpen(1)"
  >
    <mat-expansion-panel-header>
      <div class="ml-3 flex items-center">
        <div class="flex items-center justify-center h-[45px]">
          <img
            src="/assets/img/app/payment-cash.png"
            alt="Manage your order"
            class="object-contain h-full w-auto"
          />
        </div>
        <h2
          class="text-accent font-semibold text-base ml-4 uppercase leading-[1.2]"
          translate
        >
          subtitles.cash_pay
        </h2>
      </div>
      <fa-icon
        *ngIf="hasPayDelivery"
        class="text-white absolute top-[-4px] left-[-10px] bg-accent rounded-full flex justify-center items-center w-6 h-6"
        [icon]="faCheck"
      ></fa-icon>
    </mat-expansion-panel-header>

    <p translate>messages.deliverypay_message</p>
    <div fxLayout="row" class="action-row">
      <button
        *ngIf="!hasPayDelivery"
        [disabled]="saving"
        mat-raised-button
        color="accent"
        (click)="handlePayDelivery()"
      >
        <span *ngIf="!savingPayDelivery" translate>buttons.enable</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savingPayDelivery"></mat-spinner>
        </div>
      </button>
      <button
        *ngIf="hasPayDelivery"
        [disabled]="saving"
        mat-stroked-button
        color="accent"
        (click)="handlePayDelivery()"
      >
        <span *ngIf="!savingPayDelivery" translate>buttons.disable</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savingPayDelivery"></mat-spinner>
        </div>
      </button>
      <fa-icon class="button-icon" [icon]="faMoneyBillAlt"></fa-icon>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    class="border-accent border-[3px] !mx-2.5 !mb-4 !rounded-[25px] !overflow-visible"
    [expanded]="showPaymentMethod === 3"
    (opened)="setOpen(3)"
  >
    <mat-expansion-panel-header>
      <div class="ml-3 flex items-center">
        <div class="flex items-center justify-center h-[45px]">
          <img
            src="/assets/img/app/payment-cc.png"
            alt="Manage your order"
            class="object-contain h-full w-auto"
          />
        </div>
        <h2
          class="text-accent font-semibold text-base ml-4 uppercase leading-[1.2]"
          translate
        >
          subtitles.cc_payment_delivery
        </h2>
      </div>
      <fa-icon
        *ngIf="hasPayCCDelivery"
        class="text-white absolute top-[-4px] left-[-10px] bg-accent rounded-full flex justify-center items-center w-6 h-6"
        [icon]="faCheck"
      ></fa-icon>
    </mat-expansion-panel-header>

    <p translate>messages.cc_delivery_message</p>
    <div fxLayout="row" class="action-row">
      <button
        *ngIf="!hasPayCCDelivery"
        [disabled]="saving"
        mat-raised-button
        color="accent"
        (click)="handleCCDelivery()"
      >
        <span *ngIf="!savinginCCDelivery" translate>buttons.enable</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savinginCCDelivery"></mat-spinner>
        </div>
      </button>
      <button
        *ngIf="hasPayCCDelivery"
        [disabled]="saving"
        mat-stroked-button
        color="accent"
        (click)="handleCCDelivery()"
      >
        <span *ngIf="!savinginCCDelivery" translate>buttons.disable</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savinginCCDelivery"></mat-spinner>
        </div>
      </button>
      <fa-icon class="button-icon" [icon]="faCreditCard"></fa-icon>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    class="border-primary border-[3px] !mx-2.5 !mb-4 !rounded-[25px] !overflow-visible"
    [expanded]="showPaymentMethod === 6"
    (opened)="setOpen(6)"
  >
    <mat-expansion-panel-header>
      <div class="ml-3 flex items-center">
        <div class="flex items-center justify-center h-[45px]">
          <img
            src="/assets/img/app/payment-link.png"
            alt="Manage your order"
            class="object-contain h-full w-auto"
          />
        </div>
        <h2
          class="text-accent font-semibold text-base ml-6 uppercase leading-[1.2]"
          translate
        >
          subtitles.payment_link
        </h2>
      </div>
      <fa-icon
        *ngIf="hasPaymentLink"
        class="text-white absolute top-[-4px] left-[-10px] bg-accent rounded-full flex justify-center items-center w-6 h-6"
        [icon]="faCheck"
      ></fa-icon>
      <span
        class="text-xs text-white absolute top-[-12px] right-[20px] bg-primary rounded-full flex justify-center items-center w-auto h-6 px-3"
        translate
        >labels.premium</span
      >
    </mat-expansion-panel-header>

    <p translate>messages.payment_link_message</p>
    <div fxLayout="row" class="action-row">
      <button
        *ngIf="!hasPaymentLink"
        [disabled]="saving"
        mat-raised-button
        color="accent"
        (click)="handlePaymentLink()"
      >
        <span *ngIf="!savingPaymentLink" translate>buttons.enable</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savingPaymentLink"></mat-spinner>
        </div>
      </button>
      <button
        *ngIf="hasPaymentLink"
        [disabled]="saving"
        mat-stroked-button
        color="accent"
        (click)="handlePaymentLink()"
      >
        <span *ngIf="!savingPaymentLink" translate>buttons.disable</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savingPaymentLink"></mat-spinner>
        </div>
      </button>
      <fa-icon class="button-icon" [icon]="faLink"></fa-icon>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    class="border-primary border-[3px] !mx-2.5 !mb-4 !rounded-[25px] !overflow-visible"
    [expanded]="showPaymentMethod === 7"
    (opened)="setOpen(7)"
  >
    <mat-expansion-panel-header>
      <div class="ml-3 flex items-center">
        <div class="flex items-center justify-center h-[45px]">
          <img
            src="/assets/img/app/payment-bank-transfer.png"
            alt="Manage your order"
            class="object-contain h-full w-auto"
          />
        </div>
        <h2
          class="text-accent font-semibold text-base ml-4 uppercase leading-[1.2]"
          translate
        >
          subtitles.banktransfer
        </h2>
      </div>
      <fa-icon
        *ngIf="hasBankTransfer"
        class="text-white absolute top-[-4px] left-[-10px] bg-accent rounded-full flex justify-center items-center w-6 h-6"
        [icon]="faCheck"
      ></fa-icon>
      <span
        class="text-xs text-white absolute top-[-12px] right-[20px] bg-primary rounded-full flex justify-center items-center w-auto h-6 px-3"
        translate
        >labels.premium</span
      >
    </mat-expansion-panel-header>
    <p translate>messages.banktransfer_message</p>

    <div fxLayout="row" class="action-row">
      <button
        *ngIf="!openBankInfo && !hasBankTransfer"
        mat-raised-button
        color="accent"
        (click)="toggleBankTransfer()"
      >
        <span translate>buttons.enable</span>
      </button>
      <button
        *ngIf="!openBankInfo && hasBankTransfer"
        mat-raised-button
        color="accent"
        (click)="toggleBankTransfer()"
      >
        <span translate>buttons.edit</span>
      </button>
      <button
        *ngIf="openBankInfo"
        [disabled]="saving"
        mat-stroked-button
        color="accent"
        (click)="toggleBankTransfer()"
      >
        <span translate>tinyAll.close</span>
      </button>
      <fa-icon class="button-icon" [icon]="faWallet"></fa-icon>
    </div>
    <app-warning-message
      *ngIf="(user.subType !== AccountStatus.PREMIUM && user.subType !== AccountStatus.ROYAL) || user.subExpired"
      message="messages.no_available_payment_option"
      href="/~pay"
      linkLabel="labels.see_plans"
    >
    </app-warning-message>
    <form *ngIf="form && openBankInfo" novalidate [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="center">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'formFields.bank_name' | translate }}"
            formControlName="bankName"
          />
          <mat-error *ngIf="bankName.hasError('required')" translate
            >formErrors.field_required</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'formFields.account_number' | translate }}"
            formControlName="accountNumber"
          />
          <mat-error *ngIf="accountNumber.hasError('required')" translate
            >formErrors.field_required</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <mat-label><span translate>formFields.account_type</span></mat-label>
          <mat-select formControlName="accountType">
            <mat-option value="SAVING_ACCOUNT"
              ><span translate>labels.account_savings</span></mat-option
            >
            <mat-option value="CHECKING_ACCOUNT"
              ><span translate>labels.account_checking</span></mat-option
            >
          </mat-select>
          <mat-error *ngIf="accountType.hasError('required')" translate
            >formErrors.field_required</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <mat-label><span translate>formFields.id_type</span></mat-label>
          <mat-select formControlName="idType">
            <mat-option value="NATIONAL_ID"
              ><span translate>labels.cedula</span></mat-option
            >
            <mat-option value="PASSPORT"
              ><span translate>labels.passport</span></mat-option
            >
          </mat-select>
          <mat-error *ngIf="idType.hasError('required')" translate
            >formErrors.field_required</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'formFields.id_number' | translate }}"
            formControlName="idNumber"
          />
          <mat-error *ngIf="idNumber.hasError('required')" translate
            >formErrors.field_required</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'formFields.account_owner_name' | translate }}"
            formControlName="accountName"
          />
          <mat-error *ngIf="accountName.hasError('required')" translate
            >formErrors.field_required</mat-error
          >
        </mat-form-field>
      </div>
    </form>
    <div
      *ngIf="form && openBankInfo"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <button
        mat-raised-button
        [disabled]="!form || !form.dirty || form.invalid || saving"
        color="accent"
        (click)="handleBankInfoSave()"
      >
        <span *ngIf="!savingBankInfo" translate>buttons.save</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savingBankInfo"></mat-spinner>
        </div>
      </button>
      <button
        *ngIf="hasBankTransfer"
        mat-stroked-button
        [disabled]="saving"
        color="accent"
        (click)="disableBankInfoSave()"
      >
        <span *ngIf="!savingBankInfo" translate>buttons.disable</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savingBankInfo"></mat-spinner>
        </div>
      </button>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    class="border-accent border-[3px] !mx-2.5 !mb-4 !rounded-[25px] !overflow-visible"
    [expanded]="showPaymentMethod === 8"
    (opened)="setOpen(8)"
  >
    <mat-expansion-panel-header>
      <div class="ml-3 flex items-center">
        <div class="flex items-center justify-center h-[45px]">
          <img
            src="/assets/img/app/payment-tinyallpay.png"
            alt="Manage your order"
            class="object-contain h-full w-auto"
          />
        </div>
        <h2
          class="text-accent font-semibold text-base ml-4 uppercase leading-[1.2]"
          translate
        >
          subtitles.tinyallpay
        </h2>
      </div>
      <fa-icon
        *ngIf="hasTinyAllPay"
        class="text-white absolute top-[-4px] left-[-10px] bg-accent rounded-full flex justify-center items-center w-6 h-6"
        [icon]="faCheck"
      ></fa-icon>
    </mat-expansion-panel-header>

    <p translate>messages.tinyallpay_message</p>

    <div fxLayout="row" fxLayoutAlign="center center">
      <div
        class="tinyallpay"
        [ngClass]="{ disable: !isTinyAllPayAvailable }"
        fxLayoutAlign="center center"
        fxLayout="row"
      >
        <img class="tinyall-icon" src="/assets/img/app/min-icon.png" alt="TinyAll"/>
        <h1 class="pay-text">Pay</h1>
      </div>
    </div>

    <!-- TODO: TinyAll Pay -->

    <!-- <app-warning-message
    *ngIf="user.subType !== AccountStatus.PREMIUM || user.subExpired"
      message="messages.no_available_payment_option"
      href="/~pay"
      linkLabel="labels.see_plans"
    ></app-warning-message> -->

    <app-warning-message
      message="messages.feature_coming_soon"
    ></app-warning-message>

    <div fxLayout="row" class="action-row">
      <button
        *ngIf="!hasTinyAllPay"
        [disabled]="saving"
        mat-raised-button
        color="accent"
        (click)="handleTinyAllPay()"
      >
        <span *ngIf="!savingTinyAllPay" translate>buttons.i_want_it</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savingTinyAllPay"></mat-spinner>
        </div>
      </button>
      <button
        *ngIf="hasTinyAllPay"
        [disabled]="saving"
        mat-stroked-button
        color="accent"
        (click)="handleTinyAllPay()"
      >
        <span *ngIf="!savingTinyAllPay" translate
          >buttons.i_do_not_want_it</span
        >
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner diameter="25" *ngIf="savingTinyAllPay"></mat-spinner>
        </div>
      </button>
      <fa-icon class="button-icon" [icon]="faCreditCard"></fa-icon>
    </div>
  </mat-expansion-panel>
</div>

<app-loading></app-loading>
