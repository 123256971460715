// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  imageFolder: 'staging1',
  maxImageSizeInMB: 8,
  apiUrl: 'https://staging1.tinyall.app/api',
  appUrl: 'https://staging1.tinyall.app',

  firebaseConfig: {
    apiKey: "AIzaSyC6febKszCf4XvTQaCRUMVhA5WfCnpIQcA",
    authDomain: "tinyall-staging1.firebaseapp.com",
    databaseURL: "https://tinyall-staging1.firebaseio.com",
    projectId: "tinyall-staging1",
    storageBucket: "tinyall-staging1.appspot.com",
    messagingSenderId: "458757904828",
    appId: "1:458757904828:web:0650e9318efd3951bdb3b9",
    measurementId: "G-5K2TZESY4Z"
  },

  mapbox: {
    accessToken: 'pk.eyJ1IjoibW9ua2V5b3BlcmF0b3IiLCJhIjoiY2tmdTYyMXNnMGc1dzJzcDl4bGVjejRtZSJ9.9_qRy-KtQRAz73dwNzP05Q'
  },

  adminHandlerEs: 'admin-es',
  adminHandlerEn: 'admin-en',
  termsAndConditionsEs: '1a25f515-938b-4c8c-ba2f-e93f55064d1b',
  termsAndConditionsEn: '0d3e6b19-6c8a-43b6-b355-19bc7df5c1e0',
  privacyPolicyEs: '733a5e89-7763-44d0-984f-4a41c1193029',
  privacyPolicyEn: '733a5e89-7763-44d0-984f-4a41c1193029',

  pricingData: {
    ONE_MONTH: 9.99,
    SIX_MONTHS: 53.99,
    TWELVE_MONTHS: 99.99,
    ROYAL_ONE_MONTH: 29.99,
    ROYAL_SIX_MONTHS: 161.95,
    ROYAL_TWELVE_MONTHS: 299.99,
  },

  stripe: {
    products: {
      ONE_MONTH: 'test_bIY28EaJU9Kd7x6cMM',
      SIX_MONTHS: 'test_aEUcNig4ef4x5oY3cd',
      TWELVE_MONTHS: 'test_00g8x24lw9Kd3gQ146',
      ROYAL_ONE_MONTH: 'test_fZe00w19k8G9dVu8wz',
      ROYAL_SIX_MONTHS: 'test_00g4gMcS26y1eZy9AE',
      ROYAL_TWELVE_MONTHS: 'test_8wMaFa6tEe0t9Fe9AF',
    },
    customerPortal:'test_14k5mhaiW2decN2cMM',
  },

  specialPromoCode: 'FirstClass2024for30daysStaging',
};
